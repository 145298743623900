import React, { useEffect, useState } from "react";
import Error from "../error/error";
import Grid from "@mui/material/Unstable_Grid2";
import "./mediaProcessorView.css";
import { useQuery, gql, useMutation } from "@apollo/client";
import Paper from '@mui/material/Paper';
import { useParams } from "react-router-dom";
import TopMenu, { TopMenuItem, viewType } from "../../components/TopMenu/TopMenu";
import Logo from "../../components/Logo/Logo";
import { useTranslation } from 'react-i18next';
import { MwMediaDescriptions } from "./../../mwMediaDescriptions";

import {
  DndContext,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
  TouchSensor,
  closestCenter,
  MouseSensor
} from "@dnd-kit/core"
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy
} from "@dnd-kit/sortable"

import { Card, CardContent, CardHeader, Snackbar, Button, Modal, Box } from "@mui/material";

import Resizer from "react-image-file-resizer";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/plugins/counter.css";

import Video from "yet-another-react-lightbox/plugins/video";
import "yet-another-react-lightbox/styles.css";

import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css"

import moment from "moment";
import { datastore } from "../../datastore";
import SubcontractorMessages from "../../components/Messages/SubcontractorMessages";
import OrderMediaListSortableItem from "./orderMediaListSortableItem";

const SET_MESSAGE_AS_READ = gql`
  mutation setMessageAsRead($id: ID!) {
    setMessageAsRead(id: $id) {
      id
    }
  }
`;

const UPDATE_SUBCONTRACTOR_ORDER = gql`
  mutation updateSubcontractorOrder($input: UpdateSubcontractorOrderInput!) {
    updateSubcontractorOrder(input: $input) {
      id
    }
  }
`;

const uploadMediaGql = gql`
  mutation uploadMedia($input: UploadMediaInput!) {
    uploadMedia(input: $input) {
      id
    }
  }
`;

const deleteMediaGql = gql`
  mutation deleteMedia($ids: [ID!]) {
    deleteMedia(ids: $ids)
  }
`;


const GET_ORDER = gql`
  query subcontractorOrder($id: ID!) {
    subcontractorOrder(id: $id) {
      id
      status
      mediaDownloadUrl
       
      subcontractor {
        id
        language
      }
      messages {
        id
        messageSubject
        messageText
        messageSender
        threadId
        photographyCompany {
          id
          name
        }
        dateRead
        replyMessage { 
          id
        }
        createdAt
      }
      photographyCompany {
        id
        name
        address
        city
        zipCode
        cvr
        email
        logoUrl
        phoneNo
        primaryColor
        secondaryColor
        textColor
        buyingTermsUrl
        showAllowOrderConfirmedNotificationButton
        showAllowOrderMessageNotificationButton
        showAllowOrderDeliveredNotificationButton
      }
      order {
        id
        caseNo
        estateAddress
        estateZipCode
        estateCity
        estateType
        estateFloorCount
        estateRoomCount
        estateLivingArea
        estateBusinessArea
        sellerName
        sellerPhone
        sellerEmail
        sellerType
        media {
          id
          thumpnailUrl,
          orgUrl,
          heightInPx,
          widthInPx,
          mimeType,
          mediaType
          description,
          filename
        }
        customer {
            name
          }
        photeshotEvent {
          id
          start
          end
        }

      }

      createdAt
      updatedAt
    }
  }
`;

const updateMediaSortingGql = gql`
  mutation updateMediaSorting($input: [UpdateMediaSortingInput]!) {
    updateMediaSorting(input: $input)
  }
`;

const updateMediaGql = gql`
  mutation updateMedia($input: UpdateMediaInput!) {
    updateMedia(input: $input){
      id
    }
  }
`;

const uploadModalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  height: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const deleteModalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 390,
  height: 175,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const MediaProcessorView: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { subcontractorOrderId, token } = useParams();
  const [openSavedInfo, setOpenSavedInfo] = React.useState(false);
  const [openErrorInfo, setOpenErrorInfo] = React.useState(false);
  const [openFileTypeErrorInfo, setOpenFileTypeErrorInfo] = React.useState(false);

  const [openImageViewer, setOpenImageViewer] = React.useState(false);
  const [SelectedImage, setSelectedImage] = React.useState(0);
  const [order, setOrder] = React.useState<any>(null);
  const [openMediaUpload, setOpenMediaUpload] = React.useState(false);
  const [openDeleteMedia, setOpenDeleteMedia] = React.useState(false);
  const [mediaUploadItems, setmediaUploadItems] = React.useState<any[]>([]);
  const [imageUrls, setImageUrls] = React.useState<any>([]);
  const [selectedMediaItemIds, setSelectedMediaItemIds] = React.useState<string[]>([]);
  const [mediaItems, setMediaItems] = React.useState<any[]>([]);
  const captionsRef = React.useRef(null);
  const [MwMediaDescriptionsList, setMwMediaDescriptionsList] = useState<any>([]);

  useEffect(() => {
    const loadTranslations = async () => {
      // Ensure translations are loaded
      await i18n.loadNamespaces('translation');

      // Convert MwMediaDescriptions string array to object array with id and label
      const descriptionsList = MwMediaDescriptions.map((desc) => ({
        id: desc,
        label: t('mw_texts_' + desc),
      }));

      setMwMediaDescriptionsList(descriptionsList);
    };

    loadTranslations();
  }, [i18n, t]);


  localStorage.clear();
  datastore.data.token = token + '';

  const [deleteMedia] = useMutation(deleteMediaGql, {
    context: {
      headers: {
        authorization: "Bearer " + token,
      }
    }
  });

  const [uploadMedia] = useMutation(uploadMediaGql, {
    context: {
      headers: {
        authorization: "Bearer " + token,
      }
    }
  });

  const [updateMedia] = useMutation(updateMediaGql, {
    context: {
      headers: {
        authorization: "Bearer " + token,
      }
    }
  }
  );

  const [updateMediaSorting] = useMutation(updateMediaSortingGql, {
    context: {
      headers: {
        authorization: "Bearer " + token,
      }
    }
  }
  );

  const [updateMessageAsRead] = useMutation(SET_MESSAGE_AS_READ, {
    context: {
      headers: {
        authorization: "Bearer " + token,
      }
    },
    refetchQueries: [{
      query: GET_ORDER, variables: { id: subcontractorOrderId }, context: {
        headers: {
          authorization: "Bearer " + token,
        }
      },
    }]
  });
  const [updateSubcontractorOrder] = useMutation(UPDATE_SUBCONTRACTOR_ORDER);
  const thumbnailsRef = React.useRef(null);

  const { loading, error, data, refetch } = useQuery(GET_ORDER, {
    variables: {
      id: subcontractorOrderId
    },
    context: {
      headers: {
        authorization: "Bearer " + token,
      },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });
  React.useEffect(() => {
    let root = document.documentElement;
    root.style.setProperty("--text-color", "#FFF");
    root.style.setProperty("--primary-color", "#000");
    root.style.setProperty("--secondary-color", "#FFF !important");
  }, []);
  React.useEffect(() => {
    if (data && data.subcontractorOrder && data.subcontractorOrder.subcontractor) {
      i18n.changeLanguage(data.subcontractorOrder.subcontractor.language);
    }
  }, [data]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 6,
      },
    }),
  );

  if (loading) {
    if (mediaItems.length > 0) {
      setMediaItems([]);
    }
    return <div></div>;
  }

  if (error) {
    return <Error errorCode={500} />;
  }

  if (!data) {
    return (<Error errorCode={500} />);
  } else {
    if (mediaItems.length === 0 && data.subcontractorOrder.order.media.length > 0) {
      setMediaItems(data.subcontractorOrder.order.media);
    }
  }


  var fileList = [] as any;

  //set colors
  //let root = document.documentElement;
  //root.style.setProperty("--text-color", data.subcontractorOrder.photographyCompany.textColor);
  //root.style.setProperty("--primary-color", data.subcontractorOrder.photographyCompany.primaryColor);
  //root.style.setProperty("--secondary-color", data.subcontractorOrder.photographyCompany.secondaryColor);
  //root.style.setProperty("--logoUrl", "url(" + data.subcontractorOrder.photographyCompany.logoUrl + ")");

  const markAsDone = () => {
    let inputData = {} as any;
    inputData['status'] = 'COMPLETED';
    inputData['id'] = data.subcontractorOrder.id;

    updateSubcontractorOrder({
      variables: { input: inputData },
      context: {
        headers: {
          authorization: "Bearer " + token,
        },
      },
    }).then(() => {
      refetch();
      setOpenSavedInfo(true)
    }).catch((error) => {
      console.log('Error updating order:', error);
      setOpenErrorInfo(true);
    });
  }

  const downloadRaw = () => {
    let url = data.subcontractorOrder.mediaDownloadUrl;
    var link = document.createElement('a');
    link.href = url;
    link.download = 'raw.zip';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  let activeUploads = 0;
  const maxConcurrentUploads = 2;
  let fileQueue = [] as any;

  const uploadFiles = () => {
    console.log('fileList (number of files to upload): ', fileList.length);
    if (fileList.length === 0) {
      return;
    }

    //set all files as uploading on ui
    for (let i = 0; i < fileList.length; i++) {
      fileList[i].isuploaded = false;
      fileList[i].isUploading = true;
      setmediaUploadItems([...fileList]);
    }

    //fileQueue = [...fileList]; // Copy fileList to a queue
    fileQueue = fileList.map((file: any, index: any) => ({ file, index })); // Copy fileList to a queue, storing the index with each file


    processQueue();
  }

  const processQueue = () => {

    while (activeUploads < maxConcurrentUploads && fileQueue.length > 0) {
      const { file, index } = fileQueue.shift(); // Remove the first file from the queue
      activeUploads++;

      sendFile(file, index);
    }
  }

  const sendFile = async (file: any, index: number) => {
    const fileBase64 = await toBase64(file);

    const inputData = {
      subContractorOrderId: data.subcontractorOrder.id,
      orderId: data.subcontractorOrder.order.id,
      filename: file.name,
      mimeType: file.type,
      fileContentBase64: fileBase64,
    };

    setTimeout(() => {
      uploadMedia({ variables: { input: inputData } }).then(() => {

        fileList[index].isuploaded = true;
        fileList[index].isUploading = false;
        setmediaUploadItems([...fileList]);

        var missingImages = document.getElementsByClassName("lds-ripple").length;
        if (missingImages === 0 || missingImages === 1) {
          fileList = [];
          console.log('done', missingImages)
          handleClose();
          refetch();
        }

        activeUploads--; // Decrement active uploads count when a file is done uploading
        processQueue(); // Try to process the next file in the queue

      }).catch((e) => {
        console.log('error uploading media:', e)
        alert("Der opstod en fejl, filen blev ikke uploadet.");

        activeUploads--; // Decrement active uploads count when a file is done uploading
        processQueue(); // Try to process the next file in the queue
      })
    }, 100);
  }

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  async function getImageDimensions(file: any) {
    let img = new Image();
    var objectUrl = URL.createObjectURL(file);
    img.src = objectUrl;
    await img.decode();
    let width = img.width;
    let height = img.height;
    URL.revokeObjectURL(objectUrl);
    return {
      width,
      height,
    }
  }


  const handleOpen = () => {
    setmediaUploadItems([]);

    setOpenMediaUpload(true);
    setTimeout(() => {
      var fileCatcher = document.getElementById("file-catcher");
      var fileInput = document.getElementById("file-input") as HTMLInputElement;
      var fileListDisplay = document.getElementById("file-list-display");

      if (!fileCatcher || !fileInput || !fileListDisplay) {
        return;
      }

      fileCatcher.addEventListener("submit", function (evnt) {
        evnt.preventDefault();
        uploadFiles();

      });


      fileInput.addEventListener("change", async function () {
        fileList = [];
        let fileTypeError = false;
        if (!fileInput || !fileInput.files || !fileListDisplay) {
          return;
        }

        for (var i = 0; i < fileInput.files.length; i++) {
          let file = fileInput.files[i] as any;
          let { width, height } = { width: 0, height: 0 };

          if (file.type !== "image/jpeg" && file.type !== "video/mp4") {
            fileTypeError = true;

          }

          try {
            ({ width, height } = await getImageDimensions(file))
          } catch (error) {
            console.log('error', error)
          }

          file['isUploading'] = false;
          file['isuploaded'] = false;
          file['width'] = width;
          file['height'] = height;
          file['MwToLarge'] = width > 3000 || height > 3000;

          fileList.push(file);
        }

        if (fileTypeError) {

          setOpenFileTypeErrorInfo(true)
          return;
        }

        const uploadTextContainer = document.getElementsByClassName("uploadTextContainer");
        if (uploadTextContainer.length > 0) {
          (uploadTextContainer[0] as HTMLDivElement).style.display = "none";
        }
        fileInput.style.display = "none";
        fileListDisplay.style.display = "initial";

        setmediaUploadItems(fileList)
      });
    }, 100);



  }
  const handleClose = () => setOpenMediaUpload(false);


  const handleInfoClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSavedInfo(false);
  };

  const handleErrorClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErrorInfo(false);
  };

  const handleFileTypeErrorClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenFileTypeErrorInfo(false);
  };

  //map media to slides
  const slides = data.subcontractorOrder.order.media.map((media: any) => {
    if (media.mimeType === "video/mp4") {
      return { description: media.filename + "\n(" + media.heightInPx + " x " + media.widthInPx + ")", poster: media.thumpnailUrl, width: media.widthInPx, height: media.heightInPx, type: "video", sources: [{ src: media.orgUrl, type: "video/mp4" }] };
    } else {
      return { description: media.filename + "\n(" + media.heightInPx + " x " + media.widthInPx + ")", src: media.orgUrl, width: media.widthInPx, height: media.heightInPx, SrcSet: [{ src: media.thumpnailUrl }], type: "image" };
    }
  });


  if (order === null) {
    setOrder(data.subcontractorOrder.order as any);
    return (<></>);
  }

  const resizeFile = (file: File) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        90,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const showMwUploadWarning = () => {
    let showWarning = false;
    mediaUploadItems.forEach((media) => {
      if (media.MwToLarge) {
        showWarning = true;
      }
    })
    return showWarning;
  }

  const isUploading = mediaUploadItems.some((item) => item.isUploading);

  const FilePreview: React.FC<{ file: File }> = ({ file }) => {
    React.useEffect(() => {
      const imageUrl = imageUrls.find((image: any) => image.name === file.name);
      if (imageUrl) {
        return;
      }

      resizeFile(file).then((imageUrl: any) => {
        setImageUrls((prev: any) => [...prev, { name: file.name, url: imageUrl }])
      });
    }, [file]);

    const imageUrl = imageUrls.find((image: any) => image.name === file.name);

    return (
      <>
        {imageUrl && <img id="img" className="uploadItem" src={imageUrl.url} alt={file.name} />}
      </>
    );
  };

  const deleteMediasConfirmed = () => {
    if (selectedMediaItemIds) {
      deleteMedia({ variables: { ids: selectedMediaItemIds } }).then(() => {
        setOpenDeleteMedia(false);
        setSelectedMediaItemIds([])
        refetch();
      }).catch(() => {
        setOpenErrorInfo(true);
      })
    }
  }

  const handleCheckBoxClicked = (e: any) => {
    e.stopPropagation();
    if (e.target.checked) {
      setSelectedMediaItemIds([...selectedMediaItemIds, e.target.value]);
    } else {
      setSelectedMediaItemIds(selectedMediaItemIds.filter((id) => id !== e.target.value));
    }
  }

  const handleDeleteFileClicked = () => {
    setOpenDeleteMedia(true);
  }

  const handleSelectAll = () => {
    if (selectedMediaItemIds.length === data.subcontractorOrder.order.media.length) {
      setSelectedMediaItemIds([]);
    } else {
      setSelectedMediaItemIds(data.subcontractorOrder.order.media.map((media: any) => media.id));
    }
  }

  const isSelected = (id: string) => {
    return selectedMediaItemIds.includes(id);
  }

  // triggered when dragging starts
  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event
    console.log('handleDragStart - active:', active)
  }

  // triggered when dragging ends
  const handleDragEnd = (event: DragEndEvent) => {
    console.log('handleDragEnd')
    const { active, over } = event
    if (!over) return

    const activeItem = mediaItems.find((item: any) => item.id === active.id)
    const overItem = mediaItems.find((item: any) => item.id === over.id)

    if (!activeItem || !overItem) {
      return
    }

    const activeIndex = mediaItems.findIndex((item: any) => item.id === active.id)
    const overIndex = mediaItems.findIndex((item: any) => item.id === over.id)

    if (activeIndex !== overIndex) {
      const sortedItems = arrayMove<any>(mediaItems, activeIndex, overIndex)
      console.log('sortedItems:', sortedItems)

      const selectedMediaItemIds = sortedItems.map((item, index) => {
        return { id: item.id, sorting: index }
      })

      setMediaItems(sortedItems)
      updateMediaSorting({ variables: { input: selectedMediaItemIds } }).then(() => {
        //refetch();
      }).catch(() => {
        alert('Der opstod en fejl ved sortering af medierne.')

      })
    }
    //setActiveItem(undefined)
  }

  const handleDragCancel = () => {
    //setActiveItem(undefined)
  }

  const handleDescriptionChanged = (id: string, description: string) => {

    //find and update description in local state
    const mediaIndex = mediaItems.findIndex((media) => media.id === id);

    // If the media item is found, create a new array with the updated description
    if (mediaIndex !== -1) {
      const updatedMediaItems = [...mediaItems];
      updatedMediaItems[mediaIndex] = {
        ...updatedMediaItems[mediaIndex],
        description: description,
      };

      // Update the state with the new array
      setMediaItems(updatedMediaItems);
    }

    const inputData = {
      id: id,
      description: description
    }


    updateMedia({ variables: { input: inputData } }).then(() => {
      console.log('description updated');
    }).catch(() => {
      alert('Der opstod en fejl ved opdatering af beskrivelse');
    })

  }

  return (
    <div className="orderInfo MainArea mediaProcessorView">
      <header className="TopAreaMain">
        <Logo />
        <TopMenu selectedItem={TopMenuItem.MediaProcessorList} view={viewType.MediaProcessor} customerName={null} />
      </header>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className="contentContainer">
            <div className="Heading">{t('OrderInfoHeading')}</div>
            <Grid container spacing={2}>
              <Grid xs={6} spacing={2}>
                <Paper elevation={3} style={{ height: '100%' }}>
                  <Card style={{ height: '100%' }}>
                    <CardHeader title={t('OrderInfoAdressHeader')} />
                    <CardContent style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                      <div>
                        <div className="item">
                          <div className="itemLabel">{t('OrderCustomerName')}:</div>
                          <div className="itemData">{data.subcontractorOrder.order.customer.name}</div>
                        </div>
                        <div className="item">
                          <div className="itemLabel">{t('OrderItemNo')}:</div>
                          <div className="itemData">{data.subcontractorOrder.order.caseNo}</div>
                        </div>
                        <div className="item">
                          <div className="itemLabel">{t('PhotographyDate')}:</div>
                          {data.subcontractorOrder.order.photeshotEvent ? (
                            <div className="itemData">{moment(data.subcontractorOrder.order.photeshotEvent.start).format("DD-MM-YYYY")}  Kl.:{String(new Date(data.subcontractorOrder.order.photeshotEvent.start).getHours()).padStart(2, '0') + ":" + String(new Date(data.subcontractorOrder.order.photeshotEvent.start).getMinutes()).padStart(2, '0')} - {String(new Date(data.subcontractorOrder.order.photeshotEvent.end).getHours()).padStart(2, '0') + ":" + String(new Date(data.subcontractorOrder.order.photeshotEvent.end).getMinutes()).padStart(2, '0')}</div>
                          ) : (
                            <div className="itemData">Ikke planlagt</div>
                          )}
                        </div>
                        <div className="item">
                          <div className="itemLabel">{t('OrderInfoAdress')}:</div>
                          <div className="itemData">{data.subcontractorOrder.order.estateAddress}, {data.subcontractorOrder.order.estateZipCode} {data.subcontractorOrder.order.estateCity} </div>
                        </div>

                        <div className="item">
                          <div className="itemLabel">{t('OrderInfoEstateType')}:</div>
                          <div className="itemData">{data.subcontractorOrder.order.estateType}</div>
                        </div>

                        <div className="item">
                          <div className="itemLabel">{t('OrderInfoEstateFloorCount')}:</div>
                          <div className="itemData">{data.subcontractorOrder.order.estateFloorCount}</div>
                        </div>

                        <div className="item">
                          <div className="itemLabel">{t('OrderInfoEstateRoomCount')}:</div>
                          <div className="itemData">{data.subcontractorOrder.order.estateRoomCount}</div>
                        </div>

                        <div className="item">
                          <div className="itemLabel">{t('OrderInfoEstateLivingArea')}:</div>
                          <div className="itemData">{data.subcontractorOrder.order.estateLivingArea}</div>
                        </div>

                        <div className="item">
                          <div className="itemLabel">{t('OrderInfoEstateBusinessArea')}:</div>
                          <div className="itemData">{data.subcontractorOrder.order.estateBusinessArea}</div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>

              <Grid xs={6}>
                <Paper elevation={3} style={{ height: '100%' }}>
                  <SubcontractorMessages orderId={data.subcontractorOrder.order.id} subcontractorOrderId={subcontractorOrderId + ''} />
                </Paper>
              </Grid>

              {!["CANCELED"].includes(data.subcontractorOrder.status) && (
                <Grid xs={6} >
                  <Paper elevation={3} >
                    <Card >
                      <CardHeader title={t('OrderRawMaterialDownloadHeading')} />
                      <CardContent>
                        <div>
                          <div className="item">
                            <Button variant="outlined" className="" fullWidth onClick={() => downloadRaw()}>{t('OrderRawMaterialClickToDownload')}</Button>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Paper>
                </Grid>
              )}
              <Grid xs={["REJECTED", "CANCELED"].includes(data.subcontractorOrder.status) ? 12 : 6}>
                <Paper elevation={3} style={{ height: '100%' }}>
                  <Card style={{ height: '100%' }}>
                    <CardHeader title={t('OrderStatusHeading')} />
                    <CardContent>
                      {["REJECTED", "CANCELED"].includes(data.subcontractorOrder.status) ? (
                        <div>
                          <div className="item">
                            {t('OrderStatusCanceled')}
                          </div>
                        </div>
                      ) : (
                        <div>
                          {data.subcontractorOrder.status === 'COMPLETED' ? (
                            <div className="item">
                              {t('OrderStatusDelivered')}
                            </div>
                          ) : (
                            <div className="item">
                              <Button variant="outlined" className="" fullWidth onClick={() => markAsDone()}>{t('OrderRawMaterialClickToComplete')}</Button>
                            </div>
                          )}
                        </div>
                      )}
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>


              {/* Images and videos */}
              {!["CANCELED"].includes(data.subcontractorOrder.status) && (
                <Grid xs={12}>
                  <Paper elevation={3} >
                    <Card>
                      <CardHeader title={t('OrderMediaHeading')} subheader={t('OrderMediaSubHeading')}
                        action={
                          <>
                            {selectedMediaItemIds.length > 0 && (
                              <>
                                <Button variant="outlined" onClick={() => { handleDeleteFileClicked() }}>{t('OrderMediaDeleteButton')}</Button>
                                <span style={{ width: '10px' }}>&nbsp;</span>
                              </>
                            )}
                            {data.subcontractorOrder.order.media.length > 0 && (
                              <>
                                <Button variant="outlined" onClick={() => { handleSelectAll() }}>{t('OrderMediaSelectAllButton')}</Button>
                                <span style={{ width: '10px' }}>&nbsp;</span>
                              </>
                            )}
                            <Button variant="outlined" onClick={() => { handleOpen() }}>{t('OrderMediaUploadButton')}</Button>
                          </>
                        }
                      />
                      <CardContent>
                        <div>
                          <div className="mediaContainer">
                            <DndContext
                              sensors={sensors}
                              collisionDetection={closestCenter}
                              onDragStart={handleDragStart}
                              onDragEnd={handleDragEnd}
                              onDragCancel={handleDragCancel}
                            >
                              <SortableContext
                                items={mediaItems}
                                strategy={rectSortingStrategy}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap'
                                  }}
                                >
                                  {mediaItems.map((media: any) =>
                                  (
                                    <OrderMediaListSortableItem mediaDescriptions={MwMediaDescriptionsList} key={media.id} media={media} handleCheckBoxClicked={handleCheckBoxClicked} handleDescriptionChanged={handleDescriptionChanged} isSelected={isSelected(media.id)} />
                                  )
                                  )
                                  }
                                </div>
                              </SortableContext>
                            </DndContext>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>

      <Modal
        open={openMediaUpload}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={uploadModalStyle}>
          <form id='file-catcher' style={{ width: '100%' }}>
            <div className="fileuploadContainer" style={{ width: '100%' }}>
              <input id='file-input' type='file' multiple />
              <div className="uploadTextContainer">
                <i className="material-icons uploadIcon">cloud_upload</i>
                <p className="uploadText">{t('OrderUploadModalUploadText')}</p>
              </div>
              <div id='file-list-display' style={{ display: 'none' }}>
                {mediaUploadItems.map((file, index) => {
                  if (file.type === "video/mp4") {
                    return (
                      <div key={index} className="uploadItemContainer">
                        <video id="img" width="140" height="140" className="uploadItem" controls>
                          <source src={window.URL.createObjectURL(file)} type="video/mp4"></source>
                        </video>
                        <div className="uploadItemFilename">{file.name}</div>
                        {file.isUploading && (
                          <div className="lds-ripple" style={{ position: 'absolute', top: '30px', left: '30px' }}>
                            <div></div>
                            <div></div>
                          </div>
                        )}

                        {file.isuploaded && (
                          <img className="greenCheckmark" src={"/public/checkmark.png"} alt="Video uploaded" />
                        )}

                      </div>
                    )
                  } else {
                    return (
                      <div key={index} className="uploadItemContainer">
                        <FilePreview file={file} />
                        <div className="uploadItemFilename">{file.name}</div>
                        {file.isUploading && (
                          <div className="lds-ripple" style={{ position: 'absolute', top: '30px', left: '30px' }}>
                            <div></div>
                            <div></div>
                          </div>
                        )}

                        {file.isuploaded && (
                          <div className="greenCheckmark" />
                        )}

                      </div>
                    )
                  }
                })}

              </div>

            </div>
            <button id="submitbtn" type='submit' style={{ display: 'none' }}>
              Submit
            </button>
          </form>
          {!isUploading && (
            <>
              <div style={{ height: '50px', display: 'flex', alignItems: 'center' }}>
                {showMwUploadWarning() && (
                  <>
                    <div style={{ color: 'red', fontSize: '12px' }} dangerouslySetInnerHTML={{ __html: t('OrderUploadModalSizeWarning') }}></div>
                    <div style={{ width: '23%' }}>&nbsp;</div>
                  </>
                )}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '20px' }}>
                <Button variant="outlined" onClick={handleClose}>{t('OrderUploadModalCancelButton')}</Button>
                {mediaUploadItems.length > 0 && (
                  <Button variant="outlined" onClick={() => { document.getElementById('submitbtn')?.click() }} >{t('OrderUploadModalUploadButton')}</Button>
                )}
              </div>
            </>
          )}
        </Box>
      </Modal>


      <Modal
        open={openDeleteMedia}
        onClose={() => { setOpenDeleteMedia(false) }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={deleteModalStyle}>


          <div className="deleteModalContainer">
            <div className="deleteModalText">{t('OrderDeleteMediaWarning')}</div>
            <div className="deleteModalButtons">


              <Button variant="outlined" onClick={() => { setOpenDeleteMedia(false) }}>{t('OrderDeleteMediaCancelButton')}</Button>
              <span style={{ width: '15px', display: 'inline-block' }}>&nbsp;</span>
              <Button variant="outlined" onClick={deleteMediasConfirmed}>{t('OrderDeleteMediaConfirmButton')}</Button>
            </div>
          </div>
        </Box>
      </Modal>

      <Snackbar
        open={openSavedInfo}
        autoHideDuration={2000}
        message={t('OrderChangeSaved')}
        onClose={handleInfoClose}
      />

      <Snackbar
        open={openErrorInfo}
        autoHideDuration={2000}
        ContentProps={{
          sx: {
            background: "red"
          }
        }}
        message={t('OrderChangeSaveFailed')}
        onClose={handleErrorClose}
      />


      <Snackbar
        open={openFileTypeErrorInfo}
        autoHideDuration={4000}
        ContentProps={{
          sx: {
            background: "red"
          }
        }}
        message={t('FileTypeError')}
        onClose={handleFileTypeErrorClose}
      />

      <Lightbox
        styles={{ thumbnailsContainer: { backgroundColor: "#353535" } }}
        plugins={[Thumbnails, Counter, Video, Captions]}
        counter={{ container: { style: { top: "unset", bottom: 0 } } }}
        thumbnails={{ ref: thumbnailsRef }}
        open={openImageViewer}
        close={() => setOpenImageViewer(false)}
        slides={slides}
        index={SelectedImage}
        captions={{ ref: captionsRef, descriptionTextAlign: "center" }}
      />
    </div>
  );
};

export default MediaProcessorView;
