import { FC, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardHeader, Button } from "@mui/material";
import moment from "moment";
import { gql, useQuery } from "@apollo/client";
import { datastore } from "../../datastore";
import { useParams } from "react-router-dom";
import SentMessageDialog, { messageTypeEnum } from "../../pages/messagesView/components/sentMessageDialog";
import { useNavigate } from "react-router-dom";
import { FaRegPaperPlane    } from "react-icons/fa";

const GET_ORDER_MESSAGES = gql`
  query subcontractorOrder($id: ID!) {
    subcontractorOrder(id: $id) {
      id
      order {
        id
        estateAddress
        estateZipCode
        estateCity
        caseNo
      }
      messages {
        id
        messageSubject
        messageText
        messageSender
        threadId
        photographyCompany {
          id
          name
        }
        dateRead
        replyMessage { 
          id
        }
        createdAt
      }
      photographyCompany {
        id
      }
    }
  }
`;

export interface MessagesProps {
  subcontractorOrderId: string
  orderId: string
}

const SubcontractorMessages: FC<MessagesProps> = (props) => {
  const { t } = useTranslation();
  const { token } = useParams();
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const navigate = useNavigate();

  datastore.data.token = token + '';

  const { loading, error, data } = useQuery(GET_ORDER_MESSAGES, {
    variables: {
      id: props.subcontractorOrderId
    },
    context: {
      headers: {
        authorization: "Bearer " + token,
      },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  if (loading) return (
    <Card style={{ height: '100%' }}>
        <CardHeader title={t('MessagesHeader')} />
        <CardContent style={{ padding: '0px' }}>
          <div className="messageItemsHeading" key={'messageHeading'}>
            <div className="messageSubject">{t('MessagesItemHeaderSubject')}</div>
            <div className="messageDate">{t('MessagesItemHeaderRecived')}</div>
            <div className="messageReplyIcon">&nbsp;</div>
          </div>
        </CardContent>
      </Card>
  );
  if (error) return <p>{t('Error gettinge messages')}</p>;

  localStorage.setItem('selectedAddress', data.subcontractorOrder.order.caseNo + ' - ' + data.subcontractorOrder.order.estateAddress + ', ' + data.subcontractorOrder.order.estateZipCode + ' ' + data.subcontractorOrder.order.estateCity);
  localStorage.setItem('selectedCaseNo', data.subcontractorOrder.order.caseNo);
  
  const handleNewMessageClicked = () => {
    setOpenMessageModal(true);
  };

  const handleMessageItemClicked = (messageId: string) => () => {
    //find message
    let message = data.subcontractorOrder.messages.find((message: any) => message.id === messageId);
    if (!message) {
      return;
    }

    //redirect to message view /messages/:messageThreadId/:token
    navigate(`/messages/${message.threadId}/${token}`);
  }

  const handleSentMessageOnClose = (threadId: string) => {
    setOpenMessageModal(false);
    if (threadId !== null && threadId !== '') {
      navigate(`/messages/${threadId}/${token}`);
    }
  }

  const sentMessageOrderObject = {  
    id: data.subcontractorOrder.id,
    subcontractorOrderId: data.subcontractorOrder.id,
    orderId: data.subcontractorOrder.order.id,
    estateAddress: data.subcontractorOrder.order.estateAddress,
    estateZipCode: data.subcontractorOrder.order.estateZipCode,
    estateCity: data.subcontractorOrder.order.estateCity,
    caseNo: data.subcontractorOrder.order.caseNo,
    orderType: 'SUBCONTRACTOR_ORDER'
  };

  return (
    <>
      <Card style={{ height: '100%' }}>
        <CardHeader title={t('MessagesHeader')} action={<><Button variant="outlined" onClick={handleNewMessageClicked} >{t('MessageCreateNewMessage')}</Button></>} ></CardHeader>
        <CardContent style={{ padding: '0px' }}>
          <div className="messageItemsHeading" key={'messageHeading'}>
            <div className="messageSubject">{t('MessagesItemHeaderSubject')}</div>
            <div className="messageDate">{t('MessagesItemHeaderRecived')}</div>
          </div>
          <div style={{maxHeight: '320px', overflowY: 'auto'}} className="scrollbar" >
          {data.subcontractorOrder.messages.map((message: any) => {
            if (message.dateRead === null) {
              return (
                <div className="messageItemUnread" key={message.id} onClick={handleMessageItemClicked(message.id)}>
                  <div className="messageSubjectView">
                  {message.messageSubject}
                  {message.messageSender === 'CUSTOMER' && (<FaRegPaperPlane    />)}
                  </div>
                  
                  <div className="messageDate">{moment(message.createdAt).format("DD-MM-YYYY HH:mm")}</div>
                </div>
              )
            } else {
              return (
                <div className="messageItem" key={message.id} onClick={handleMessageItemClicked(message.id)}>
                  <div className="messageSubjectView">
                  {message.messageSubject}
                  {message.messageSender === 'CUSTOMER' && (<FaRegPaperPlane    />)}
                  </div>
                 
                  <div className="messageDate">{moment(message.createdAt).format("DD-MM-YYYY HH:mm")}</div>
                </div>
              )
            }
          })}
          </div>
        </CardContent>
      </Card>

      <SentMessageDialog key={'OrderSentMessage'} order={sentMessageOrderObject} isVisible={openMessageModal} onClose={handleSentMessageOnClose} messageType={messageTypeEnum.NORMAL_MESSAGE} />

        {/*}
      <MessageModal orderId={props.orderId} subcontractorOrderId={props.subcontractorOrderId} photographyCompanyId={data.subcontractorOrder.photographyCompany.id} open={openMessageModal} onClose={() => setOpenMessageModal(false)} view={messageView} messageObject={messageObj} />
          {*/}
      </>
  );
}

export default SubcontractorMessages;