import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: {
          "OrderInfoHeading": "Order Information",
          "OrderInfoAdressHeader": "Information",
          "OrderInfoAdress": "Address",
          "OrderInfoEstateType": "Property Type",
          "OrderInfoEstateFloorCount": "Number of Floors",
          "OrderInfoEstateRoomCount": "Number of Rooms",
          "OrderInfoEstateLivingArea": "Living Area",
          "OrderInfoEstateBusinessArea": "Business Area",
          "OrderRawMaterialDownloadHeading": "Raw Material",
          "OrderRawMaterialClickToDownload": "Click here to download the raw material.",
          "OrderStatusHeading": "Status",
          "OrderStatusDelivered": "The order has been delivered",
          "OrderRawMaterialClickToComplete": "Click here to mark the order as delivered.",
          "OrderMediaHeading": "Media",
          "OrderMediaSubHeading": "Upload the finished material here",
          "OrderMediaUploadButton": "Upload Material",
          "OrderUploadModalUploadText": "Select one or more files or drag them here.",
          "OrderUploadModalSizeWarning": "One or more of the images exceed the dimensions 3000 x 3000.<br />If they are uploaded to the housing systems, they will downscale the images, which can result in loss of quality.",
          "OrderUploadModalCancelButton": "Cancel",
          "OrderUploadModalUploadButton": "Upload",
          "OrderChangeSaved": "The change has been saved.",
          "OrderChangeSaveFailed": "Updating the order failed.",
          "OrderEstateContactHeading": "Property Actor",
          "OrderEstateContactName": "Name",
          "OrderEstateContactPhoneNo": "Phone No.",
          "OrderEstateContactEmail": "Email",
          "OrderMediaTextHeading": "Texts",
          "OrderMediaTextSubHeading": "Fill in the following texts",
          "OrderMediaInputHeading": "Heading",
          "OrderMediaInputTextSmall": "Text - small",
          "OrderMediaInputTextMedium": "Text - medium",
          "OrderMediaInputTextLarge": "Text - long",
          "OrderMediaInputTextSoMe": "Text - SoMe",
          "OrderMediaInputTextWebsite": "Text - Website",
          "FileTypeError": "One or more of the files are not JPG or MP4.",
          "PhotographyDate": "Photography date",
          "OrderListHeading": "Orders",
          "OrderListHeaderCaseNo": "Item no.",
          "OrderListHeaderAddress": "Address",
          "OrderListHeaderCustomer": "Customer",
          "OrderListHeaderImageCount": "Images",
          "OrderListHeaderCreated": "Created",
          "OrderListHeaderStatus": "Status",
          "TopMenu_Orders": "Orders",
          "TopMenu_Messages": "Messages",
          "subcontractor_status_sent": "Received",
          "subcontractor_status_downloaded": "Downloaded",
          "subcontractor_status_completed": "Delivered",
          "subcontractor_status_rejected": "Rejected",
          "subcontractor_status_canceled": "Canceled",
          "OrderListHeaderPhoteshotDate": "Photoshot Date",
          "MessagesHeader": "Messages",
          "MessagesItemHeaderSubject": "Subject",
          "MessagesItemHeaderRecived": "Send/Recived",
          "MessageCreateNewMessage": "Create new message",
          "CloseMessage": "Close",
          "ReplyMessage": "Reply",
          "SentMessage": "Send",
          "OrderCustomerName": "Real Estate Broker",
          "OrderItemNo": "Item no.",
          "OrderMediaSelectAllButton": "Select all",
          "OrderMediaDeleteButton": "Delete",
          "OrderDeleteMediaConfirmButton": "Delete",
          "OrderDeleteMediaCancelButton": "Cancel",
          "OrderDeleteMediaWarning": "You are about to delete one or more images. Are you sure you want to continue?",
          "OrderStatusCanceled": "The order has been canceled",
          "ReplyMessage": "Reply",
          "Subject": "Subject",
          "SentMessage": "Send",
          "Message": "Message",
          "SentDate": "Sent Date",
          "BeskedMessage": "Message",
          "messageViewHeadingMessages": "Messages",
          "messageViewCreateNewMessage": "Create new message",
          "messageViewSubject": "Subject",
          "messageViewCreated": "Created",
          "messageViewTo": "To",
          "messageViewCase": "Case",
          "messageViewSubContractOrder": "Order",
          "messageViewSubContractOrderDate": "Order date",
          "messages.emptyThread": "Communicate with your photographer.",
          "messages.emptyThreadText": "Create or select a conversation to get started.",
          "messages.MarkerHeadingView": "Media",
          "messages.MarkerHeadingEdit": "Edit",
          "messages.button.cancel": "Cancel",
          "messages.button.ok": "OK",
          "messages.choose.images": "Choose images",
          "messages.image.name": "Name",
          "messages.image.size": "Size",
          "messages.button.addToMessage": "Add to message",
          "messages.alert.addMessage": "Add a message",
          "messages.alert.selectOrder": "Select an order",
          "messages.alert.addSubject": "Add a subject",
          "messages.heading.sentMessage": "Send message",
          "messages.button.sentMessage": "Send message",
          "button.back": "Back",
          "onlyUnreadMessages": "Only unread",
          "image.name": "Name",
          "image.size": "Size",
          "image.description": "Description",

          "mw_texts_Streetview": "Street view",
          "mw_texts_1. sal": "1st floor",
          "mw_texts_1. sal med mål": "1st floor with dimensions",
          "mw_texts_1. sal uden mål": "1st floor without dimensions",
          "mw_texts_2. sal": "2nd floor",
          "mw_texts_2. sal med mål": "2nd floor with dimensions",
          "mw_texts_2. sal uden mål": "2nd floor without dimensions",
          "mw_texts_3. sal": "3rd floor",
          "mw_texts_3. sal med mål": "3rd floor with dimensions",
          "mw_texts_3. sal uden mål": "3rd floor without dimensions",
          "mw_texts_4. sal": "4th floor",
          "mw_texts_4. sal med mål": "4th floor with dimensions",
          "mw_texts_4. sal uden mål": "4th floor without dimensions",
          "mw_texts_5. sal": "5th floor",
          "mw_texts_5. sal med mål": "5th floor with dimensions",
          "mw_texts_5. sal uden mål": "5th floor without dimensions",
          "mw_texts_Affald": "Waste",
          "mw_texts_Affaldsskakt": "Waste chute",
          "mw_texts_Aftægtsbolig": "Retirement home",
          "mw_texts_Aktivitetsrum": "Activity room",
          "mw_texts_Alkove": "Alcove",
          "mw_texts_Alrum": "Living area",
          "mw_texts_Altan": "Balcony",
          "mw_texts_Andet": "Other",
          "mw_texts_Anneks": "Annex",
          "mw_texts_Anneks med mål": "Annex with dimensions",
          "mw_texts_Anneks uden mål": "Annex without dimensions",
          "mw_texts_Arbejdsværelse": "Study",
          "mw_texts_Arkiv": "Archive",
          "mw_texts_Atelier": "Studio",
          "mw_texts_Atriumgård": "Courtyard",
          "mw_texts_Auditorium": "Auditorium",
          "mw_texts_Bad": "Bath",
          "mw_texts_Badebro": "Bathing jetty",
          "mw_texts_Badeværelse": "Bathroom",
          "mw_texts_Badeværelse med spa": "Bathroom with spa",
          "mw_texts_Bag facade": "Behind facade",
          "mw_texts_Baggang": "Back hallway",
          "mw_texts_Baggård": "Backyard",
          "mw_texts_Baghave": "Back garden",
          "mw_texts_Balkon": "Balcony",
          "mw_texts_Bar": "Bar",
          "mw_texts_Beliggenhed": "Location",
          "mw_texts_Bilværksted": "Car workshop",
          "mw_texts_Boks": "Box",
          "mw_texts_Boligfilm": "Home film",
          "mw_texts_Boligvideo": "Home video",
          "mw_texts_Brus": "Shower",
          "mw_texts_Brusebadeværelse": "Shower bathroom",
          "mw_texts_Bryggers": "Utility room",
          "mw_texts_Brændeovn": "Wood stove",
          "mw_texts_Brændeskur": "Wood shed",
          "mw_texts_Brændsel": "Fuel",
          "mw_texts_Butik": "Shop",
          "mw_texts_Butikslokale": "Shop premises",
          "mw_texts_By": "City",
          "mw_texts_Byggegrund": "Building plot",
          "mw_texts_Byggeret": "Building rights",
          "mw_texts_Børneværelse": "Children's room",
          "mw_texts_Café": "Café",
          "mw_texts_Carport": "Carport",
          "mw_texts_Cellekontor": "Cell office",
          "mw_texts_Chefkontor": "Executive office",
          "mw_texts_Cykelskur": "Bike shed",
          "mw_texts_Cykler": "Bikes",
          "mw_texts_Dagligstue": "Living room",
          "mw_texts_Depot": "Storage",
          "mw_texts_Diskotek": "Disco",
          "mw_texts_Disponibel": "Available",
          "mw_texts_Disponibelt rum": "Available room",
          "mw_texts_Dronefilm": "Drone film",
          "mw_texts_Dobbeltværelse": "Double room",
          "mw_texts_Domicil": "Headquarters",
          "mw_texts_Drivhus": "Greenhouse",
          "mw_texts_Ejendom 1": "Property 1",
          "mw_texts_Ejendom 2": "Property 2",
          "mw_texts_Ejendom 3": "Property 3",
          "mw_texts_Ejendom 4": "Property 4",
          "mw_texts_Ejendom 5": "Property 5",
          "mw_texts_Ejendommen": "The property",
          "mw_texts_Elevator": "Elevator",
          "mw_texts_Enkeltmandskontor": "Single office",
          "mw_texts_Entré": "Entrance",
          "mw_texts_Erhverv": "Business",
          "mw_texts_Fabrik": "Factory",
          "mw_texts_Facade": "Facade",
          "mw_texts_Facade bolig": "Residential facade",
          "mw_texts_Facade erhverv": "Commercial facade",
          "mw_texts_Facade havesiden": "Garden facade",
          "mw_texts_Facade lang": "Long facade",
          "mw_texts_Facade side": "Side facade",
          "mw_texts_Festlokale": "Party room",
          "mw_texts_Festsal": "Banquet hall",
          "mw_texts_Fitness lokale": "Fitness room",
          "mw_texts_Fordelingsgang": "Hallway",
          "mw_texts_Forgang": "Front hallway",
          "mw_texts_Forhave": "Front garden",
          "mw_texts_Forretning": "Business",
          "mw_texts_Forretningslokale": "Business premises",
          "mw_texts_Forrum": "Anteroom",
          "mw_texts_Forsidebillede": "Front picture",
          "mw_texts_Forstue": "Front room",
          "mw_texts_Fortrappe": "Front staircase",
          "mw_texts_Forældresoveværelse": "Master bedroom",
          "mw_texts_Fransk altan": "French balcony",
          "mw_texts_Frokoststue": "Lunchroom",
          "mw_texts_Fyr": "Boiler",
          "mw_texts_Fyrrum": "Boiler room",
          "mw_texts_Fælles": "Common",
          "mw_texts_Fælles areal": "Common area",
          "mw_texts_Fælles indgang": "Common entrance",
          "mw_texts_Fælles reception": "Shared reception",
          "mw_texts_Fællesrum": "Common room",
          "mw_texts_Gade": "Street",
          "mw_texts_Gang": "Hallway",
          "mw_texts_Gangbro": "Skywalk",
          "mw_texts_Garage": "Garage",
          "mw_texts_Garderobe": "Wardrobe",
          "mw_texts_Gavl": "Gable",
          "mw_texts_Gildesal": "Banquet hall",
          "mw_texts_Gildestue": "Party room",
          "mw_texts_Grillbar": "Grill bar",
          "mw_texts_Grovkøkken": "Utility kitchen",
          "mw_texts_Grund": "Plot",
          "mw_texts_Gæstebadeværelse": "Guest bathroom",
          "mw_texts_Gæstetoilet": "Guest toilet",
          "mw_texts_Gæsteværelse": "Guest room",
          "mw_texts_Gågade": "Pedestrian street",
          "mw_texts_Gård": "Courtyard",
          "mw_texts_Gårdareal": "Courtyard area",
          "mw_texts_Gårdhave": "Courtyard garden",
          "mw_texts_Gårdmiljø": "Courtyard setting",
          "mw_texts_Gårdsiden": "Courtyard side",
          "mw_texts_Gårdsplads": "Courtyard yard",
          "mw_texts_Hal": "Hall",
          "mw_texts_Hall": "Hall",
          "mw_texts_Have": "Garden",
          "mw_texts_Havudsigt": "Sea view",
          "mw_texts_Havestue": "Garden room",
          "mw_texts_Hems": "Loft",
          "mw_texts_Hems": "Sleeping loft",
          "mw_texts_Hesteboks": "Horse box",
          "mw_texts_Hestefold": "Paddock",
          "mw_texts_Hestesolarium": "Horse solarium",
          "mw_texts_Hobby": "Hobby",
          "mw_texts_Hobbyrum": "Hobby room",
          "mw_texts_Hotel": "Hotel",
          "mw_texts_Hus": "House",
          "mw_texts_Hvilerum": "Rest room",
          "mw_texts_Højlager": "High bay warehouse",
          "mw_texts_Ikke udgravet": "Not excavated",
          "mw_texts_Ildsted": "Fireplace",
          "mw_texts_Illustration": "Illustration",
          "mw_texts_Introfilm": "Intro film",
          "mw_texts_Indendørs": "Indoors",
          "mw_texts_Indendørs swimmingpool": "Indoor swimming pool",
          "mw_texts_Indgang": "Entrance",
          "mw_texts_Indgang erhverv": "Business entrance",
          "mw_texts_Indgangsparti": "Entrance area",
          "mw_texts_Indkørsel": "Driveway",
          "mw_texts_Institution": "Institution",
          "mw_texts_Kammer": "Chamber",
          "mw_texts_Kantine": "Canteen",
          "mw_texts_Karbadeværelse": "Tub bathroom",
          "mw_texts_Kedelrum": "Boiler room",
          "mw_texts_Klinik": "Clinic",
          "mw_texts_Konferencerum": "Conference room",
          "mw_texts_Kontor": "Office",
          "mw_texts_Kontor m møderum": "Office with meeting room",
          "mw_texts_Kopirum": "Copy room",
          "mw_texts_Kort": "Map",
          "mw_texts_Krybekælder": "Crawl space",
          "mw_texts_Kursuslokale": "Training room",
          "mw_texts_Kvist": "Dormer",
          "mw_texts_Kvistværelse": "Dormer room",
          "mw_texts_Kælder": "Basement",
          "mw_texts_Kælder med mål": "Basement with dimensions",
          "mw_texts_Kælder uden mål": "Basement without dimensions",
          "mw_texts_Kælderplan": "Basement plan",
          "mw_texts_Køjerum": "Bunk room",
          "mw_texts_Køkken": "Kitchen",
          "mw_texts_Køkken 1. sal": "1st floor kitchen",
          "mw_texts_Køkken alrum": "Kitchen-living area",
          "mw_texts_Køl": "Cooling",
          "mw_texts_Lade": "Barn",
          "mw_texts_Lager": "Warehouse",
          "mw_texts_Lagerhal": "Storage hall",
          "mw_texts_Lagerrum": "Storage room",
          "mw_texts_Ledporte": "Electric gates",
          "mw_texts_Loft": "Attic",
          "mw_texts_Lokation": "Location",
          "mw_texts_Luftfoto": "Aerial photo",
          "mw_texts_Manøvrerum": "Maneuvering room",
          "mw_texts_Marker": "Fields",
          "mw_texts_Maskinhus": "Machine house",
          "mw_texts_Mastefoto": "Mast photo",
          "mw_texts_Masterbedroom": "Master bedroom",
          "mw_texts_Matrikelkort": "Cadastral map",
          "mw_texts_Med mål": "With dimensions",
          "mw_texts_Mellemgang": "Corridor",
          "mw_texts_Modelfoto": "Model photo",
          "mw_texts_Motionsrum": "Exercise room",
          "mw_texts_Møderum": "Meeting room",
          "mw_texts_Omgivelser": "Surroundings",
          "mw_texts_Omklædning": "Changing room",
          "mw_texts_Område": "Area",
          "mw_texts_Opgang": "Staircase",
          "mw_texts_Opholdsrum": "Lounge",
          "mw_texts_Opholdsstue": "Living room",
          "mw_texts_Oplagsplads": "Storage space",
          "mw_texts_Opmagasinering": "Storage",
          "mw_texts_Orangeri": "Orangery",
          "mw_texts_Overdækket": "Covered",
          "mw_texts_Overdækket indgang": "Covered entrance",
          "mw_texts_Overdækket terrasse": "Covered terrace",
          "mw_texts_Overetage": "Upper floor",
          "mw_texts_Ovn": "Oven",
          "mw_texts_P pladser": "Parking spaces",
          "mw_texts_Panoramaudsigt": "Panoramic view",
          "mw_texts_Park": "Park",
          "mw_texts_Parkering": "Parking",
          "mw_texts_Parkeringsareal": "Parking area",
          "mw_texts_Parkeringsforhold": "Parking conditions",
          "mw_texts_Parkeringsplads": "Parking lot",
          "mw_texts_Pejs": "Fireplace",
          "mw_texts_Pejsestue": "Fireplace room",
          "mw_texts_Pergola": "Pergola",
          "mw_texts_Personalefaciliteter": "Staff facilities",
          "mw_texts_Plantegning": "Floor plan",
          "mw_texts_Pool": "Pool",
          "mw_texts_Poolrum": "Pool room",
          "mw_texts_Port": "Gate",
          "mw_texts_Port facade": "Gate facade",
          "mw_texts_Port indgang": "Gate entrance",
          "mw_texts_Port indkørsel": "Gate driveway",
          "mw_texts_Portræt": "Portrait",
          "mw_texts_Produktionslokale": "Production facility",
          "mw_texts_Projekt": "Project",
          "mw_texts_Pult": "Desk",
          "mw_texts_Pulterrum": "Storeroom",
          "mw_texts_Påklædningsværelse": "Dressing room",
          "mw_texts_Rampe": "Ramp",
          "mw_texts_Reception": "Reception",
          "mw_texts_Redskabsrum": "Tool room",
          "mw_texts_Repos": "Landing",
          "mw_texts_Restaurant": "Restaurant",
          "mw_texts_Ridebane": "Riding arena",
          "mw_texts_Ridehal": "Riding hall",
          "mw_texts_Rum": "Room",
          "mw_texts_Rytterstue": "Riders' lounge",
          "mw_texts_Sadelrum": "Saddle room",
          "mw_texts_Salon": "Salon",
          "mw_texts_Salgsareal": "Sales area",
          "mw_texts_Sauna": "Sauna",
          "mw_texts_Serverrum": "Server room",
          "mw_texts_Set fra haven": "View from the garden",
          "mw_texts_Set fra vejen": "View from the road",
          "mw_texts_Showroom": "Showroom",
          "mw_texts_Skab": "Cabinet",
          "mw_texts_Skabe": "Cabinets",
          "mw_texts_Skabsgang": "Closet hallway",
          "mw_texts_Skabsrum": "Closet space",
          "mw_texts_Skole": "School",
          "mw_texts_Skov": "Forest",
          "mw_texts_Skrald": "Trash",
          "mw_texts_Skunk": "Knee wall storage",
          "mw_texts_Skur": "Shed",
          "mw_texts_Sluseporte": "Floodgates",
          "mw_texts_Solgård": "Sun courtyard",
          "mw_texts_Soveværelse": "Bedroom",
          "mw_texts_Spa": "Spa",
          "mw_texts_Spisekammer": "Pantry",
          "mw_texts_Spisekrog": "Dining nook",
          "mw_texts_Spisekøkken": "Eat-in kitchen",
          "mw_texts_Spiseplads": "Dining area",
          "mw_texts_Spisestue": "Dining room",
          "mw_texts_Stald": "Stable",
          "mw_texts_Standard indendørs": "Standard indoors",
          "mw_texts_Standard udendørs": "Standard outdoors",
          "mw_texts_Storrumskontor": "Open office",
          "mw_texts_Strand": "Beach",
          "mw_texts_Strandgrund": "Beachfront plot",
          "mw_texts_Stue": "Living room",
          "mw_texts_Stue ensuite": "Living room ensuite",
          "mw_texts_Stue m. karnap": "Living room with bay window",
          "mw_texts_Stueetage": "Ground floor",
          "mw_texts_Stueetage med mål": "Ground floor with dimensions",
          "mw_texts_Stueetage uden mål": "Ground floor without dimensions",
          "mw_texts_Stueplan": "Ground plan",
          "mw_texts_Svalegang": "Gallery corridor",
          "mw_texts_Swimmingpool": "Swimming pool",
          "mw_texts_Søudsigt": "Lake view",
          "mw_texts_Tagetage": "Attic floor",
          "mw_texts_Tagterrasse": "Rooftop terrace",
          "mw_texts_Teknik": "Technical room",
          "mw_texts_Terrasse": "Terrace",
          "mw_texts_Thekøkken": "Tea kitchen",
          "mw_texts_Til salgsopstilling": "For sales listing",
          "mw_texts_Tilbygning": "Extension",
          "mw_texts_Tilbygning med mål": "Extension with dimensions",
          "mw_texts_Tilbygning uden mål": "Extension without dimensions",
          "mw_texts_Toilet": "Toilet",
          "mw_texts_Trappe": "Stairs",
          "mw_texts_Trappeopgang": "Stairwell",
          "mw_texts_Trapperum": "Staircase room",
          "mw_texts_Træningslokale": "Training room",
          "mw_texts_TV stue": "TV room",
          "mw_texts_Tårn": "Tower",
          "mw_texts_Tørrerum": "Drying room",
          "mw_texts_Uddannelseslokale": "Educational facility",
          "mw_texts_Udekøkken": "Outdoor kitchen",
          "mw_texts_Uden mål": "Without dimensions",
          "mw_texts_Udendørs": "Outdoors",
          "mw_texts_Udendørsareal": "Outdoor area",
          "mw_texts_Udendørsbokse": "Outdoor stalls",
          "mw_texts_Udenomsarealer": "Surrounding areas",
          "mw_texts_Udestue": "Sunroom",
          "mw_texts_Udhus": "Outbuilding",
          "mw_texts_Udhus med mål": "Outbuilding with dimensions",
          "mw_texts_Udhus uden mål": "Outbuilding without dimensions",
          "mw_texts_Udsigt": "View",
          "mw_texts_Udsigt fra toppen": "View from the top",
          "mw_texts_Udsigt til dam": "View of the pond",
          "mw_texts_Udsigt til sø": "View of the lake",
          "mw_texts_Udstillingsvinduer": "Display windows",
          "mw_texts_Udstykningsforslag": "Subdivision proposal",
          "mw_texts_Underetage": "Lower floor",
          "mw_texts_Undervisningslokale": "Classroom",
          "mw_texts_Uudnyttet": "Unused",
          "mw_texts_Uudnyttet loft": "Unused attic",
          "mw_texts_Varmerum": "Heating room",
          "mw_texts_Vask": "Sink",
          "mw_texts_Vaskerum": "Laundry room",
          "mw_texts_Veranda": "Veranda",
          "mw_texts_Video": "Video",
          "mw_texts_Viktualierum": "Cold storage room",
          "mw_texts_Vindfang": "Entrance hall",
          "mw_texts_Vinkælder": "Wine cellar",
          "mw_texts_Vinterhave": "Winter garden",
          "mw_texts_Vognly": "Cart shed",
          "mw_texts_Værelse": "Room",
          "mw_texts_Værksted": "Workshop",
          "mw_texts_Walker": "Walker",
          "mw_texts_Walkin closet": "Walk-in closet",
          "mw_texts_WC": "WC",
          "mw_texts_Nederste plan": "Lower floor",
          "mw_texts_Øverste plan": "Top floor",
          "mw_texts_Alle plan": "All floors",
          "mw_texts_SoMe Film": "Social media film"

        }
      },
      da: {
        translations: {
          "OrderInfoHeading": "Ordre information",
          "OrderInfoAdressHeader": "Information",
          "OrderInfoAdress": "Adresse",
          "OrderInfoEstateType": "Ejendomstype",
          "OrderInfoEstateFloorCount": "Antal etager",
          "OrderInfoEstateRoomCount": "Antal rum",
          "OrderInfoEstateLivingArea": "Boligareal",
          "OrderInfoEstateBusinessArea": "Erhvervs areal",
          "OrderRawMaterialDownloadHeading": "Råmateriale",
          "OrderRawMaterialClickToDownload": "Klik her for at hente det rå materiale.",
          "OrderStatusHeading": "Status",
          "OrderStatusDelivered": "Ordren er leveret",
          "OrderRawMaterialClickToComplete": "Klik her for at markere ordren som leveret.",
          "OrderMediaHeading": "Media",
          "OrderMediaSubHeading": "Upload det færdige materiale her",
          "OrderMediaUploadButton": "Upload meteriale",
          "OrderUploadModalUploadText": "Vælg en eller flere filer eller træk dem herhen.",
          "OrderUploadModalSizeWarning": "Et eller flere af billederne, overstiger dimensionerne 3000 x 3000.<br />Hvis de uploades til boligsystemerne vil de nedskalere billederne, hvilket kan resultere i tab af kvalitet.",
          "OrderUploadModalCancelButton": "Fortryd",
          "OrderUploadModalUploadButton": "Upload",
          "OrderChangeSaved": "Ændringen er blevet gemt.",
          "OrderChangeSaveFailed": "Opdatering af ordren fejlede.",
          "OrderEstateContactHeading": "Kontaktperson",
          "OrderEstateContactName": "Navn",
          "OrderEstateContactPhoneNo": "Telefon nr.",
          "OrderEstateContactEmail": "E-mail",
          "OrderMediaTextHeading": "Tekster",
          "OrderMediaTextSubHeading": "Udfyld nedenstående tekster",
          "OrderMediaInputHeading": "Overskrift",
          "OrderMediaInputTextSmall": "Tekst - lille",
          "OrderMediaInputTextMedium": "Tekst - mellem",
          "OrderMediaInputTextLarge": "Tekst - lang",
          "OrderMediaInputTextSoMe": "Tekst - SoMe",
          "OrderMediaInputTextWebsite": "Tekst - Website",
          "FileTypeError": "En eller flere af filerne er ikke JPG eller MP4.",
          "PhotographyDate": "Fotografering d.",
          "OrderListHeading": "Bestillinger",
          "OrderListHeaderCaseNo": "Sagsnr.",
          "OrderListHeaderAddress": "Adresse",
          "OrderListHeaderCustomer": "Kunde",
          "OrderListHeaderImageCount": "Antal billeder",
          "OrderListHeaderCreated": "Created",
          "OrderListHeaderStatus": "Status",
          "TopMenu_Orders": "Bestillinger",
          "TopMenu_Messages": "Beskeder",
          "subcontractor_status_sent": "Modtaget",
          "subcontractor_status_downloaded": "Hentet",
          "subcontractor_status_completed": "Ordren er leveret",
          "subcontractor_status_rejected": "Ordren er afvist",
          "subcontractor_status_canceled": "Ordren er annulleret",
          "OrderListHeaderPhoteshotDate": "Fotografering d.",
          "MessagesHeader": "Beskeder",
          "MessagesItemHeaderSubject": "Emne",
          "MessagesItemHeaderRecived": "Sendt/Modtaget",
          "MessageCreateNewMessage": "Opret ny besked",
          "CloseMessage": "Luk",
          "ReplyMessage": "Svar",
          "SentMessage": "Send",
          "OrderCustomerName": "Ejendomsmægler",
          "OrderItemNo": "Sagsnr.",
          "OrderMediaSelectAllButton": "Vælg alle",
          "OrderMediaDeleteButton": "Slet",
          "OrderDeleteMediaConfirmButton": "Slet",
          "OrderDeleteMediaCancelButton": "Fortryd",
          "OrderDeleteMediaWarning": "Du er ved at slette et eller flere billeder. Er du sikker på at du vil fortsætte?",
          "OrderStatusCanceled": "Ordren er annulleret",
          "ReplyMessage": "Svar på bekeden",
          "Subject": "Emne",
          "SentMessage": "Send besked",
          "Message": "Besked",
          "SentDate": "Sendt d.",
          "BeskedMessage": "Besked",
          "messageViewHeadingMessages": "Beskeder",
          "messageViewCreateNewMessage": "Opret ny besked",
          "messageViewSubject": "Emne",
          "messageViewCreated": "Oprettet",
          "messageViewTo": "Til",
          "messageViewCase": "Sag",
          "messageViewSubContractOrder": "Ordre",
          "messageViewSubContractOrderDate": "Ordre dato",
          "messages.emptyThread": "Kommunikér med din fotograf.",
          "messages.emptyThreadText": "Opret eller vælg en samtale for at komme i gang.",
          "messages.MarkerHeadingView": "Medie",
          "messages.MarkerHeadingEdit": "Rediger",
          "messages.button.cancel": "Annuller",
          "messages.button.ok": "OK",
          "messages.choose.images": "Vælg billeder",
          "messages.image.name": "Navn",
          "messages.image.size": "Størrelse",
          "messages.button.addToMessage": "Tilføj til besked",
          "messages.alert.addMessage": "Tilføj en besked",
          "messages.alert.selectOrder": "Vælg en ordre",
          "messages.alert.addSubject": "Tilføj et emne",
          "messages.heading.sentMessage": "Send besked",
          "messages.button.sentMessage": "Send besked",
          "button.back": "Tilbage",
          "onlyUnreadMessages": "Kun ulæste",
          "image.name": "Navn",
          "image.size": "Størrelse",
          "image.description": "Beskrivelse",
          "mw_texts_Streetview": "Streetview",
          "mw_texts_1. sal": "1. sal",
          "mw_texts_1. sal med mål": "1. sal med mål",
          "mw_texts_1. sal uden mål": "1. sal uden mål",
          "mw_texts_2. sal": "2. sal",
          "mw_texts_2. sal med mål": "2. sal med mål",
          "mw_texts_2. sal uden mål": "2. sal uden mål",
          "mw_texts_3. sal": "3. sal",
          "mw_texts_3. sal med mål": "3. sal med mål",
          "mw_texts_3. sal uden mål": "3. sal uden mål",
          "mw_texts_4. sal": "4. sal",
          "mw_texts_4. sal med mål": "4. sal med mål",
          "mw_texts_4. sal uden mål": "4. sal uden mål",
          "mw_texts_5. sal": "5. sal",
          "mw_texts_5. sal med mål": "5. sal med mål",
          "mw_texts_5. sal uden mål": "5. sal uden mål",
          "mw_texts_Affald": "Affald",
          "mw_texts_Affaldsskakt": "Affaldsskakt",
          "mw_texts_Aftægtsbolig": "Aftægtsbolig",
          "mw_texts_Aktivitetsrum": "Aktivitetsrum",
          "mw_texts_Alkove": "Alkove",
          "mw_texts_Alrum": "Alrum",
          "mw_texts_Altan": "Altan",
          "mw_texts_Andet": "Andet",
          "mw_texts_Anneks": "Anneks",
          "mw_texts_Anneks med mål": "Anneks med mål",
          "mw_texts_Anneks uden mål": "Anneks uden mål",
          "mw_texts_Arbejdsværelse": "Arbejdsværelse",
          "mw_texts_Arkiv": "Arkiv",
          "mw_texts_Atelier": "Atelier",
          "mw_texts_Atriumgård": "Atriumgård",
          "mw_texts_Auditorium": "Auditorium",
          "mw_texts_Bad": "Bad",
          "mw_texts_Badebro": "Badebro",
          "mw_texts_Badeværelse": "Badeværelse",
          "mw_texts_Badeværelse med spa": "Badeværelse med spa",
          "mw_texts_Bag facade": "Bag facade",
          "mw_texts_Baggang": "Baggang",
          "mw_texts_Baggård": "Baggård",
          "mw_texts_Baghave": "Baghave",
          "mw_texts_Balkon": "Balkon",
          "mw_texts_Bar": "Bar",
          "mw_texts_Beliggenhed": "Beliggenhed",
          "mw_texts_Bilværksted": "Bilværksted",
          "mw_texts_Boks": "Boks",
          "mw_texts_Boligfilm": "Boligfilm",
          "mw_texts_Boligvideo": "Boligvideo",
          "mw_texts_Brus": "Brus",
          "mw_texts_Brusebadeværelse": "Brusebadeværelse",
          "mw_texts_Bryggers": "Bryggers",
          "mw_texts_Brændeovn": "Brændeovn",
          "mw_texts_Brændeskur": "Brændeskur",
          "mw_texts_Brændsel": "Brændsel",
          "mw_texts_Butik": "Butik",
          "mw_texts_Butikslokale": "Butikslokale",
          "mw_texts_By": "By",
          "mw_texts_Byggegrund": "Byggegrund",
          "mw_texts_Byggeret": "Byggeret",
          "mw_texts_Børneværelse": "Børneværelse",
          "mw_texts_Café": "Café",
          "mw_texts_Carport": "Carport",
          "mw_texts_Cellekontor": "Cellekontor",
          "mw_texts_Chefkontor": "Chefkontor",
          "mw_texts_Cykelskur": "Cykelskur",
          "mw_texts_Cykler": "Cykler",
          "mw_texts_Dagligstue": "Dagligstue",
          "mw_texts_Depot": "Depot",
          "mw_texts_Diskotek": "Diskotek",
          "mw_texts_Disponibel": "Disponibel",
          "mw_texts_Disponibelt rum": "Disponibelt rum",
          "mw_texts_Dronefilm": "Dronefilm",
          "mw_texts_Dobbeltværelse": "Dobbeltværelse",
          "mw_texts_Domicil": "Domicil",
          "mw_texts_Drivhus": "Drivhus",
          "mw_texts_Ejendom 1": "Ejendom 1",
          "mw_texts_Ejendom 2": "Ejendom 2",
          "mw_texts_Ejendom 3": "Ejendom 3",
          "mw_texts_Ejendom 4": "Ejendom 4",
          "mw_texts_Ejendom 5": "Ejendom 5",
          "mw_texts_Ejendommen": "Ejendommen",
          "mw_texts_Elevator": "Elevator",
          "mw_texts_Enkeltmandskontor": "Enkeltmandskontor",
          "mw_texts_Entré": "Entré",
          "mw_texts_Erhverv": "Erhverv",
          "mw_texts_Fabrik": "Fabrik",
          "mw_texts_Facade": "Facade",
          "mw_texts_Facade bolig": "Facade bolig",
          "mw_texts_Facade erhverv": "Facade erhverv",
          "mw_texts_Facade havesiden": "Facade havesiden",
          "mw_texts_Facade lang": "Facade lang",
          "mw_texts_Facade side": "Facade side",
          "mw_texts_Festlokale": "Festlokale",
          "mw_texts_Festsal": "Festsal",
          "mw_texts_Fitness lokale": "Fitness lokale",
          "mw_texts_Fordelingsgang": "Fordelingsgang",
          "mw_texts_Forgang": "Forgang",
          "mw_texts_Forhave": "Forhave",
          "mw_texts_Forretning": "Forretning",
          "mw_texts_Forretningslokale": "Forretningslokale",
          "mw_texts_Forrum": "Forrum",
          "mw_texts_Forsidebillede": "Forsidebillede",
          "mw_texts_Forstue": "Forstue",
          "mw_texts_Fortrappe": "Fortrappe",
          "mw_texts_Forældresoveværelse": "Forældresoveværelse",
          "mw_texts_Fransk altan": "Fransk altan",
          "mw_texts_Frokoststue": "Frokoststue",
          "mw_texts_Fyr": "Fyr",
          "mw_texts_Fyrrum": "Fyrrum",
          "mw_texts_Fælles": "Fælles",
          "mw_texts_Fælles areal": "Fælles areal",
          "mw_texts_Fælles indgang": "Fælles indgang",
          "mw_texts_Fælles reception": "Fælles reception",
          "mw_texts_Fællesrum": "Fællesrum",
          "mw_texts_Gade": "Gade",
          "mw_texts_Gang": "Gang",
          "mw_texts_Gangbro": "Gangbro",
          "mw_texts_Garage": "Garage",
          "mw_texts_Garderobe": "Garderobe",
          "mw_texts_Gavl": "Gavl",
          "mw_texts_Gildesal": "Gildesal",
          "mw_texts_Gildestue": "Gildestue",
          "mw_texts_Grillbar": "Grillbar",
          "mw_texts_Grovkøkken": "Grovkøkken",
          "mw_texts_Grund": "Grund",
          "mw_texts_Gæstebadeværelse": "Gæstebadeværelse",
          "mw_texts_Gæstetoilet": "Gæstetoilet",
          "mw_texts_Gæsteværelse": "Gæsteværelse",
          "mw_texts_Gågade": "Gågade",
          "mw_texts_Gård": "Gård",
          "mw_texts_Gårdareal": "Gårdareal",
          "mw_texts_Gårdhave": "Gårdhave",
          "mw_texts_Gårdmiljø": "Gårdmiljø",
          "mw_texts_Gårdsiden": "Gårdsiden",
          "mw_texts_Gårdsplads": "Gårdsplads",
          "mw_texts_Hal": "Hal",
          "mw_texts_Hall": "Hall",
          "mw_texts_Have": "Have",
          "mw_texts_Havudsigt": "Havudsigt",
          "mw_texts_Havestue": "Havestue",
          "mw_texts_Hems": "Hems",
          "mw_texts_Hesteboks": "Hesteboks",
          "mw_texts_Hestefold": "Hestefold",
          "mw_texts_Hestesolarium": "Hestesolarium",
          "mw_texts_Hobby": "Hobby",
          "mw_texts_Hobbyrum": "Hobbyrum",
          "mw_texts_Hotel": "Hotel",
          "mw_texts_Hus": "Hus",
          "mw_texts_Hvilerum": "Hvilerum",
          "mw_texts_Højlager": "Højlager",
          "mw_texts_Ikke_udgravet": "Ikke udgravet",
          "mw_texts_Ildsted": "Ildsted",
          "mw_texts_Illustration": "Illustration",
          "mw_texts_Introfilm": "Introfilm",
          "mw_texts_Indendørs": "Indendørs",
          "mw_texts_Indendørs swimmingpool": "Indendørs swimmingpool",
          "mw_texts_Indgang": "Indgang",
          "mw_texts_Indgang erhverv": "Indgang erhverv",
          "mw_texts_Indgangsparti": "Indgangsparti",
          "mw_texts_Indkørsel": "Indkørsel",
          "mw_texts_Institution": "Institution",
          "mw_texts_Kammer": "Kammer",
          "mw_texts_Kantine": "Kantine",
          "mw_texts_Karbadeværelse": "Karbadeværelse",
          "mw_texts_Kedelrum": "Kedelrum",
          "mw_texts_Klinik": "Klinik",
          "mw_texts_Konferencerum": "Konferencerum",
          "mw_texts_Kontor": "Kontor",
          "mw_texts_Kontor m møderum": "Kontor m møderum",
          "mw_texts_Kopirum": "Kopirum",
          "mw_texts_Kort": "Kort",
          "mw_texts_Krybekælder": "Krybekælder",
          "mw_texts_Kursuslokale": "Kursuslokale",
          "mw_texts_Kvist": "Kvist",
          "mw_texts_Kvistværelse": "Kvistværelse",
          "mw_texts_Kælder": "Kælder",
          "mw_texts_Kælder med mål": "Kælder med mål",
          "mw_texts_Kælder uden mål": "Kælder uden mål",
          "mw_texts_Kælderplan": "Kælderplan",
          "mw_texts_Køjerum": "Køjerum",
          "mw_texts_Køkken": "Køkken",
          "mw_texts_Køkken 1. sal": "Køkken 1. sal",
          "mw_texts_Køkken alrum": "Køkken alrum",
          "mw_texts_Køl": "Køl",
          "mw_texts_Lade": "Lade",
          "mw_texts_Lager": "Lager",
          "mw_texts_Lagerhal": "Lagerhal",
          "mw_texts_Lagerrum": "Lagerrum",
          "mw_texts_Ledporte": "Ledporte",
          "mw_texts_Loft": "Loft",
          "mw_texts_Lokation": "Lokation",
          "mw_texts_Luftfoto": "Luftfoto",
          "mw_texts_Manøvrerum": "Manøvrerum",
          "mw_texts_Marker": "Marker",
          "mw_texts_Maskinhus": "Maskinhus",
          "mw_texts_Mastefoto": "Mastefoto",
          "mw_texts_Masterbedroom": "Masterbedroom",
          "mw_texts_Matrikelkort": "Matrikelkort",
          "mw_texts_Med mål": "Med mål",
          "mw_texts_Mellemgang": "Mellemgang",
          "mw_texts_Modelfoto": "Modelfoto",
          "mw_texts_Motionsrum": "Motionsrum",
          "mw_texts_Møderum": "Møderum",
          "mw_texts_Omgivelser": "Omgivelser",
          "mw_texts_Omklædning": "Omklædning",
          "mw_texts_Område": "Område",
          "mw_texts_Opgang": "Opgang",
          "mw_texts_Opholdsrum": "Opholdsrum",
          "mw_texts_Opholdsstue": "Opholdsstue",
          "mw_texts_Oplagsplads": "Oplagsplads",
          "mw_texts_Opmagasinering": "Opmagasinering",
          "mw_texts_Orangeri": "Orangeri",
          "mw_texts_Overdækket": "Overdækket",
          "mw_texts_Overdækket indgang": "Overdækket indgang",
          "mw_texts_Overdækket terrasse": "Overdækket terrasse",
          "mw_texts_Overetage": "Overetage",
          "mw_texts_Ovn": "Ovn",
          "mw_texts_P pladser": "P pladser",
          "mw_texts_Panoramaudsigt": "Panoramaudsigt",
          "mw_texts_Park": "Park",
          "mw_texts_Parkering": "Parkering",
          "mw_texts_Parkeringsareal": "Parkeringsareal",
          "mw_texts_Parkeringsforhold": "Parkeringsforhold",
          "mw_texts_Parkeringsplads": "Parkeringsplads",
          "mw_texts_Pejs": "Pejs",
          "mw_texts_Pejsestue": "Pejsestue",
          "mw_texts_Pergola": "Pergola",
          "mw_texts_Personalefaciliteter": "Personalefaciliteter",
          "mw_texts_Plantegning": "Plantegning",
          "mw_texts_Pool": "Pool",
          "mw_texts_Poolrum": "Poolrum",
          "mw_texts_Port": "Port",
          "mw_texts_Port facade": "Port facade",
          "mw_texts_Port indgang": "Port indgang",
          "mw_texts_Port indkørsel": "Port indkørsel",
          "mw_texts_Portræt": "Portræt",
          "mw_texts_Produktionslokale": "Produktionslokale",
          "mw_texts_Projekt": "Projekt",
          "mw_texts_Pult": "Pult",
          "mw_texts_Pulterrum": "Pulterrum",
          "mw_texts_Påklædningsværelse": "Påklædningsværelse",
          "mw_texts_Rampe": "Rampe",
          "mw_texts_Reception": "Reception",
          "mw_texts_Redskabsrum": "Redskabsrum",
          "mw_texts_Repos": "Repos",
          "mw_texts_Restaurant": "Restaurant",
          "mw_texts_Ridebane": "Ridebane",
          "mw_texts_Ridehal": "Ridehal",
          "mw_texts_Rum": "Rum",
          "mw_texts_Rytterstue": "Rytterstue",
          "mw_texts_Sadelrum": "Sadelrum",
          "mw_texts_Salon": "Salon",
          "mw_texts_Salgsareal": "Salgsareal",
          "mw_texts_Sauna": "Sauna",
          "mw_texts_Serverrum": "Serverrum",
          "mw_texts_Set fra haven": "Set fra haven",
          "mw_texts_Set fra vejen": "Set fra vejen",
          "mw_texts_Showroom": "Showroom",
          "mw_texts_Skab": "Skab",
          "mw_texts_Skabe": "Skabe",
          "mw_texts_Skabsgang": "Skabsgang",
          "mw_texts_Skabsrum": "Skabsrum",
          "mw_texts_Skole": "Skole",
          "mw_texts_Skov": "Skov",
          "mw_texts_Skrald": "Skrald",
          "mw_texts_Skunk": "Skunk",
          "mw_texts_Skur": "Skur",
          "mw_texts_Sluseporte": "Sluseporte",
          "mw_texts_Solgård": "Solgård",
          "mw_texts_Soveværelse": "Soveværelse",
          "mw_texts_Spa": "Spa",
          "mw_texts_Spisekammer": "Spisekammer",
          "mw_texts_Spisekrog": "Spisekrog",
          "mw_texts_Spisekøkken": "Spisekøkken",
          "mw_texts_Spiseplads": "Spiseplads",
          "mw_texts_Spisestue": "Spisestue",
          "mw_texts_Stald": "Stald",
          "mw_texts_Standard indendørs": "Standard indendørs",
          "mw_texts_Standard udendørs": "Standard udendørs",
          "mw_texts_Storrumskontor": "Storrumskontor",
          "mw_texts_Strand": "Strand",
          "mw_texts_Strandgrund": "Strandgrund",
          "mw_texts_Stue": "Stue",
          "mw_texts_Stue ensuite": "Stue ensuite",
          "mw_texts_Stue m. karnap": "Stue m. karnap",
          "mw_texts_Stueetage": "Stueetage",
          "mw_texts_Stueetage med mål": "Stueetage med mål",
          "mw_texts_Stueetage uden mål": "Stueetage uden mål",
          "mw_texts_Stueplan": "Stueplan",
          "mw_texts_Svalegang": "Svalegang",
          "mw_texts_Swimmingpool": "Swimmingpool",
          "mw_texts_Søudsigt": "Søudsigt",
          "mw_texts_Tagetage": "Tagetage",
          "mw_texts_Tagterrasse": "Tagterrasse",
          "mw_texts_Teknik": "Teknik",
          "mw_texts_Terrasse": "Terrasse",
          "mw_texts_Thekøkken": "Thekøkken",
          "mw_texts_Til salgsopstilling": "Til salgsopstilling",
          "mw_texts_Tilbygning": "Tilbygning",
          "mw_texts_Tilbygning med mål": "Tilbygning med mål",
          "mw_texts_Tilbygning uden mål": "Tilbygning uden mål",
          "mw_texts_Toilet": "Toilet",
          "mw_texts_Trappe": "Trappe",
          "mw_texts_Trappeopgang": "Trappeopgang",
          "mw_texts_Trapperum": "Trapperum",
          "mw_texts_Træningslokale": "Træningslokale",
          "mw_texts_TV stue": "TV stue",
          "mw_texts_Tårn": "Tårn",
          "mw_texts_Tørrerum": "Tørrerum",
          "mw_texts_Uddannelseslokale": "Uddannelseslokale",
          "mw_texts_Udekøkken": "Udekøkken",
          "mw_texts_Uden mål": "Uden mål",
          "mw_texts_Udendørs": "Udendørs",
          "mw_texts_Udendørsareal": "Udendørsareal",
          "mw_texts_Udendørsbokse": "Udendørsbokse",
          "mw_texts_Udenomsarealer": "Udenomsarealer",
          "mw_texts_Udestue": "Udestue",
          "mw_texts_Udhus": "Udhus",
          "mw_texts_Udhus med mål": "Udhus med mål",
          "mw_texts_Udhus uden mål": "Udhus uden mål",
          "mw_texts_Udsigt": "Udsigt",
          "mw_texts_Udsigt fra toppen": "Udsigt fra toppen",
          "mw_texts_Udsigt til dam": "Udsigt til dam",
          "mw_texts_Udsigt til sø": "Udsigt til sø",
          "mw_texts_Udstillingsvinduer": "Udstillingsvinduer",
          "mw_texts_Udstykningsforslag": "Udstykningsforslag",
          "mw_texts_Underetage": "Underetage",
          "mw_texts_Undervisningslokale": "Undervisningslokale",
          "mw_texts_Uudnyttet": "Uudnyttet",
          "mw_texts_Uudnyttet loft": "Uudnyttet loft",
          "mw_texts_Varmerum": "Varmerum",
          "mw_texts_Vask": "Vask",
          "mw_texts_Vaskerum": "Vaskerum",
          "mw_texts_Veranda": "Veranda",
          "mw_texts_Video": "Video",
          "mw_texts_Viktualierum": "Viktualierum",
          "mw_texts_Vindfang": "Vindfang",
          "mw_texts_Vinkælder": "Vinkælder",
          "mw_texts_Vinterhave": "Vinterhave",
          "mw_texts_Vognly": "Vognly",
          "mw_texts_Værelse": "Værelse",
          "mw_texts_Værksted": "Værksted",
          "mw_texts_Walker": "Walker",
          "mw_texts_Walkin closet": "Walkin closet",
          "mw_texts_WC": "WC",
          "mw_texts_Nederste plan": "Nederste plan",
          "mw_texts_Øverste plan": "Øverste plan",
          "mw_texts_Alle plan": "Alle plan",
          "mw_texts_SoMe Film": "SoMe Film"

          // Add more translations here
        }
      },
      // Add more languages here
    },
    lng: "da-DK",
    fallbackLng: "da-DK",
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ","
    },
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    react: {
      wait: true
    }
  });

export default i18n;