// Chakra imports
import React from "react";
import './messageViewEmpty.css';
import { useTranslation } from "react-i18next";

interface IProps {

}


          
const MessageViewEmpty: React.FC<IProps> = () => {
  const { t } = useTranslation();
  
  return (
    <div className="messageViewEmpty">
     
        <div className="messageViewEmptyContainer">
          <div className="messageViewEmptyText">
          <div className="messageViewEmptyTextHeading">
          <div className="messageViewEmptyImage" ></div>
            {t('messages.emptyThread')}
            </div>
            <div className="messageViewEmptyTextNormal">
              {t('messages.emptyThreadText')}
            </div>
          </div>
        </div>

    </div>)
}

export default MessageViewEmpty;
