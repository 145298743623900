import { CSSProperties, forwardRef, HTMLAttributes, useEffect } from "react"
import { FaRegPlayCircle } from "react-icons/fa";
import { Autocomplete, TextField } from "@mui/material";

import React from "react";
import { t } from "i18next";
import i18n from "../../i18n";

type Props = {
    media: any
    handleCheckBoxClicked?: any
    handleDescriptionChanged?: any
    isSelected?: boolean
    isOpacityEnabled?: boolean
    isDragging?: boolean
    mediaDescriptions: any
} & HTMLAttributes<HTMLDivElement>



const OrderMediaListThumbnail = forwardRef<HTMLDivElement, Props>(
    ({ media, isSelected, handleCheckBoxClicked, handleDescriptionChanged, isOpacityEnabled, isDragging, style, ...props }, ref) => {
        const [mediaDescription, setMediaDescription] = React.useState(media.description);
        const [translatedMediaDescription, setTranslatedMediaDescription] = React.useState(null);
  
        const styles: CSSProperties = {
            opacity: isOpacityEnabled ? "0.4" : "1",
            cursor: isDragging ? "grabbing" : "grab",
            lineHeight: "0.5",
            transform: isDragging ? "scale(1.15)" : "scale(1)",
            ...style
        }

        useEffect(() => {
            const loadTranslations = async () => {
              // Ensure translations are loaded
              await i18n.loadNamespaces('translation');
        
              // Find the translated media description
              const translatedDescription = props.mediaDescriptions.find((desc:any) => desc.id === mediaDescription);

              //translate translatedDescription.label
                if (translatedDescription) {
                    translatedDescription.label = t(translatedDescription.label);
                    setTranslatedMediaDescription(translatedDescription);
                }


              
            };
        
            loadTranslations();
          }, [i18n, mediaDescription, t]);

        const handleDescriptionChangedIntarnal = (id: string, value: any, updateServer: boolean) => {
            console.log(id);
            setMediaDescription(value.id);

            if (!updateServer) {
                return;
            }

            if (media.description === value.id) {
                return;
            }

            //updateServer
            handleDescriptionChanged(id, value.id);
        }

        const handlemediaItemThumbnailContainerClick = (e: any, media: any) => {
            e.stopPropagation();
            const checkBox = document.getElementById('checkbox_' + media.id) as HTMLInputElement;
            if (checkBox) {
                checkBox.click();
            }
        }

        
       console.log('translatedMediaDescription', translatedMediaDescription);

        return (
            <div className="mediaProcessorViewMediaItem" ref={ref} style={styles} {...props} key={'Thumbnail_' + media.id}  >

                <div className="mediaItemThumbnailContainer" onClick={(e) => { handlemediaItemThumbnailContainerClick(e, media); }}>

                    {media.mediaType === 'IMAGE' ? (
                        <img src={media.thumpnailUrl} alt="placeholder" className="mediaItemThumbnail" />
                    ) : (
                        <>
                            <img src={media.thumpnailUrl} alt="placeholder" className="mediaItemThumbnail" />
                            <div className="orderMediaListVideoPlayIconContainer">
                                <FaRegPlayCircle className="orderMediaListVideoPlayIcon" />
                            </div>
                        </>
                    )}
                </div>
                <div style={{ fontSize: '12px', lineHeight: '16px', paddingLeft: '10px', paddingTop: '10px', paddingBottom: '10px' }}>
                    <div style={{ display: 'flex' }}>
                        <div><b>{t('image.name')}:&nbsp;</b></div>
                        <div className="mediaText">{media.filename}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div><b>{t('image.size')}:&nbsp;</b></div>
                        <div>{media.heightInPx} x {media.widthInPx}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div><b>{t('image.description')}:</b></div>
                        <div></div>
                    </div>
                    {translatedMediaDescription && (
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: '100%' }}>
                            <Autocomplete
                                onChange={(value: any, item: any) => { handleDescriptionChangedIntarnal(media.id, item, true) }}
                                fullWidth
                                style={{ fontSize: '10px' }}
                                disablePortal
                                options={props.mediaDescriptions}
                                defaultValue={translatedMediaDescription}
                                //isOptionEqualToValue={(option, value) => option.id === value.id}
                                classes={{ input: 'autoComplete-text', option: 'autoComplete-text' }}
                                renderInput={(params) => {
                                    let input = { ...params };
                                    input.InputProps.className = "MuiAutocomplete-inputRoot autocom"
                                    input.inputProps.className = "MuiAutocomplete-input autocom"
                                    return <TextField  variant="standard"  {...input} />
                                }
                                }
                            />
                        </div>
                    </div>
                    )}

                </div>



                <div className="SelectFileCheckboxContainer">
                    <input id={'checkbox_' + media.id} checked={isSelected} type="checkbox" value={media.id} className={'SelectFileCheckbox_' + media.id} name="SelectFileCheckbox" onChange={() => { }} onClick={handleCheckBoxClicked} />
                </div>
            </div>
        );
    }
)

export default OrderMediaListThumbnail;