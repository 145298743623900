// Chakra imports
import {
  Modal,
  Button,
  Box
} from "@mui/material";
import React from "react";
import { gql, useQuery } from "@apollo/client";
import './messageViewImageSelector.css';
import { datastore } from "./../../../datastore";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IProps {
  isVisible: boolean;
  orderId: string;
  onClose: any;
}

const GET_ORDER_MEDIA = gql`
  query order($id: ID!) {
    getOrder(id: $id) {
      id
      media {
        id
        heightInPx
        widthInPx
        filename
        fileSizeInKB
        orientation
        thumpnailUrl
        orgUrl
        mimeType
        description
        mediaType
      }
    }
  }
`;

const MessageViewImageSelector: React.FC<IProps> = ({ orderId, onClose, isVisible }) => {
  const { token } = useParams();
  const { t } = useTranslation();
  const [selectedMedias, setSelectedMedias] = React.useState<any[]>([]);

  datastore.data.token = token + '';
  const { loading, error, data } = useQuery(
    GET_ORDER_MEDIA,
    {
      variables: { id: orderId },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      context: {
        headers: {
          authorization: "Bearer " + datastore.data.token,
        },
      },
    }
  );

  if (loading) return <p></p>; //loading

  if (error) {
    return (<p>Error getting message from server (1)</p>); //TODO: make error component
  }

  if (!data) {
    return (<p>Error getting message from server (2)</p>);
  }

  if (!isVisible) {
    return null;
  }

  const CloseMessageMediaSelector = () => {
    onClose([]);
  }

  const AddSelectedToMessage = () => {
    onClose([...selectedMedias]);
  }

  const isSelected = (mediaId: string) => {
    //check if media is in selected list
    const filtered = selectedMedias.filter((media: any) => {
      if (media.id === mediaId) {
        return true;
      }
    });

    if (filtered.length > 0) {
      return true;
    }
    return false;
  }

  const handleCheckBoxClicked = (media: any) => {
    //add media to selected list if not already there
    if (isSelected(media.id)) {
      const filtered = selectedMedias.filter((selectedMedia: any) => {
        if (selectedMedia.id !== media.id) {
          return true;
        }
      });
      setSelectedMedias(filtered);
    } else {
      setSelectedMedias([...selectedMedias, media]);
    }
  }

  const messageModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal onClose={CloseMessageMediaSelector} open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Box sx={messageModalStyle}>
          <div style={{ width: '830px' }}>
            <h1>{t('messages.choose.images')}</h1>
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', overflow: 'auto', maxHeight: '400px' }}>
                {data.getOrder.media.map((media: any) => (
                  <div key={'image_' + media.id} className="mediaItem" onClick={() => { handleCheckBoxClicked(media) }} style={{ cursor: 'pointer' }}>
                    <img src={media.thumpnailUrl} alt="placeholder" style={{ maxWidth: '180px' }} />
                    <div style={{ fontSize: '12px', lineHeight: '16px', paddingLeft: '18px', paddingTop: '10px', paddingBottom: '10px' }}>
                      <div style={{ display: 'flex' }}>
                        <div><b>{t('messages.image.name')}:&nbsp;</b></div>
                        <div>{media.filename}</div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div><b>{t('messages.image.size')}:&nbsp;</b></div>
                        <div>{media.heightInPx} x {media.widthInPx}</div>
                      </div>
                    </div>
                    <div className="SelectFileCheckboxContainer">
                      <input id={'checkbox_' + media.id} checked={isSelected(media.id)} type="checkbox" value={media.id} className={'SelectFileCheckbox_' + media.id} name="SelectFileCheckbox" onChange={() => { }} onClick={() => { handleCheckBoxClicked(media) }} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="BottomContainer">
              <Button onClick={CloseMessageMediaSelector} variant="outlined">{t('messages.button.cancel')}</Button>
              <Button onClick={AddSelectedToMessage} variant="outlined" >{t('messages.button.addToMessage')}</Button>
            </div>

          </div>
        </Box>
      </>
    </Modal>
  )
}

export default MessageViewImageSelector;
