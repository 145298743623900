import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import "./messagesView.css";
import Paper from '@mui/material/Paper';
import TopMenu, { TopMenuItem, viewType } from "../../components/TopMenu/TopMenu";
import Logo from "../../components/Logo/Logo";
import { Card, CardContent } from "@mui/material";
import { useTranslation } from 'react-i18next';
import MessageView from "./components/messageView";
import MessageList, { MessageFilter } from "./components/messageList";
import { useParams } from "react-router-dom";

const MessagesView: React.FC = () => {
  const { t } = useTranslation();
  const [updateState, setUpdateState] = React.useState(0);
  const { messageThreadId } = useParams();

  let selectedTread = "";
  if (messageThreadId) {
    selectedTread = messageThreadId;
  }

  const [selectedMessageThreadId, setSelectedMessageThreadId] = useState(selectedTread as string);

  React.useEffect(() => {
    let root = document.documentElement;
    root.style.setProperty("--text-color", "#FFF");
    root.style.setProperty("--primary-color", "#000");
    root.style.setProperty("--secondary-color", "#FFF !important");
  }, []);

  const handleMessageSelected = (threadId: string, reloadList: boolean) => {
    setSelectedMessageThreadId(threadId);
    if (reloadList) {
      //reload message list
      setUpdateState(updateState + 1);
    }
  }

  const topMenuView = () => {
    if (localStorage.getItem('customerId')) {
      return viewType.EstateAgent;
    }
    return viewType.MediaProcessor;
  }

  const getSearchStr = () => {
    if (messageThreadId) {
      if (localStorage.getItem("selectedCaseNo") && localStorage.getItem("selectedCaseNo") !== "null") {
        return localStorage.getItem("selectedCaseNo") as string;
      }
    }
    return "";
  }

  const handleBackButtonClicked = () => {
    window.history.back()
  }


  return (
    <div className="orderInfo MainArea">
      <header className="TopAreaMain">
        <Logo />
        <TopMenu selectedItem={TopMenuItem.Messages} view={topMenuView()} customerName={null} />
      </header>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className="contentContainer">
            {messageThreadId ? (
              <div className="Heading" style={{fontSize:'22px', height:'40px',lineHeight: '24px'}}>{t('messageViewHeadingMessages')}<br/>{localStorage.getItem('selectedAddress')}</div>
            ) : (
              <div className="Heading">{t('messageViewHeadingMessages')}</div>
            )}
            {messageThreadId && (
              <div className="backButton"onClick={handleBackButtonClicked}>{t('button.back')}</div>
            )}
            <Grid container xs={12} spacing={2}>
              <Grid xs={4}>
                <Paper elevation={3} style={{ height: '100%' }} >
                  <Card style={{ height: '100%' }}>
                    <CardContent>
                      <MessageList key={updateState} order={null}  searchStr={getSearchStr()} fotographerUserIds={null} onMessageSelected={handleMessageSelected} />
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
              <Grid xs={8}>
                <Paper elevation={3} style={{ height: '100%' }} >
                  <Card style={{ height: '100%' }}>
                    <CardContent style={{ height: '100%' }}>
                      <MessageView threadId={selectedMessageThreadId} />
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default MessagesView;
