import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { HTMLAttributes } from "react"
import OrderMediaListThumbnail from "./orderMediaListThumbnail"


  type Props = {
    media: any
    handleCheckBoxClicked?: any
    handleDescriptionChanged?: any
    isSelected?: boolean
    isDragging?: boolean
    mediaDescriptions: any
} & HTMLAttributes<HTMLDivElement>

const OrderMediaListSortableItem = ({ media,mediaDescriptions, ...props }: Props) => {

    const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
        id: media.id,
      })
    
      const styles = {
        transform: CSS.Transform.toString(transform),
        transition: transition || undefined,
      }


    return (
        <OrderMediaListThumbnail 
        media={media}
        mediaDescriptions={mediaDescriptions}
        ref={setNodeRef} 
        style={styles}  
        isDragging={isDragging}
        isOpacityEnabled={isDragging}     
        {...props}
        {...attributes}
        {...listeners} />
    );
}

export default OrderMediaListSortableItem;