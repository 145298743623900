import React from "react";
import { gql, useQuery } from "@apollo/client";
import { datastore } from "./../../../datastore";
const GET_MESSAGE_COUNT_GQL = gql`
query getMessageCount {
  getUnreadMessagesCount
}`;


interface IProps { }

const MessageCount: React.FC<IProps> = () => {
  const { loading, error, data } = useQuery(GET_MESSAGE_COUNT_GQL, {
      context: {
        headers: {
          authorization: "Bearer " + datastore.data.token,
        },
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      pollInterval: 10000
    });

  if (loading) return <p></p>; //loading

  if (error) {
    return (<p>Error getting Unread Messages Count from server (1)</p>); //TODO: make error component
  }

  if (!data) {
    return (<p>Error getting Unread Messages Count from server (2)</p>);
  }

  const messageCountElm = document.getElementsByClassName('messageCount') as HTMLCollectionOf<HTMLElement>;
  if (messageCountElm.length > 0) {
    if (data.getUnreadMessagesCount > 0) {
      messageCountElm[0].style.display = 'block';
      if (data.getUnreadMessagesCount > 9) {
        messageCountElm[0].innerHTML = data.getUnreadMessagesCount + "+";
      } else {
        messageCountElm[0].innerHTML = data.getUnreadMessagesCount;
      }
    } else {
      messageCountElm[0].style.display = 'none';
    }
  }

  return null
};

export default MessageCount;
