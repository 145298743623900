import { FC, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardHeader, Button } from "@mui/material";
import moment from "moment";
import { gql, useQuery } from "@apollo/client";
import { datastore } from "../../datastore";
import SentMessageDialog, { messageTypeEnum } from "../../pages/messagesView/components/sentMessageDialog";
import { FaRegPaperPlane    } from "react-icons/fa";
const GET_ORDER_MESSAGES = gql`
  query getMessagesByOrder($id: ID!) {
    getOrder(id: $id) {
      id
      estateAddress
      estateZipCode
      estateCity
      caseNo
      customer {
        id  
      }
    }
    getMessagesByOrder(id: $id) {
      id
      threadId
      messageSubject
      messageText
      messageSender
      dateRead
      createdAt
    }
  }
`;

export interface MessagesProps {
  orderId: string
}

const CustomerMessages: FC<MessagesProps> = (props) => {
  const { t } = useTranslation();

  const [openMessageModal, setOpenMessageModal] = useState(false);

  const token = localStorage.getItem('id_token') + '';
  datastore.data.token = token;

  const { loading, error, data } = useQuery(GET_ORDER_MESSAGES, {
    variables: {
      id: props.orderId
    },
    context: {
      headers: {
        authorization: "Bearer " + token,
      },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  if (loading) return (
    <Card style={{ height: '100%' }}>
      <CardHeader title={t('MessagesHeader')} />
      <CardContent style={{ padding: '0px' }}>
        <div className="messageItemsHeading" key={'messageHeading'}>
          <div className="messageSubject">{t('MessagesItemHeaderSubject')}</div>
          <div className="messageDate">{t('MessagesItemHeaderRecived')}</div>
          <div className="messageReplyIcon">&nbsp;</div>
        </div>
      </CardContent>
    </Card>
  );
  if (error) return <p>{t('Error gettinge messages')}</p>;

  localStorage.setItem('selectedAddress', data.getOrder.caseNo + ' - ' + data.getOrder.estateAddress + ', ' + data.getOrder.estateZipCode + ' ' + data.getOrder.estateCity);
  localStorage.setItem('selectedCaseNo', data.getOrder.caseNo);

  const handleNewMessageClicked = () => {
    // setMessageView(messageViewType.SentMessage);

    setOpenMessageModal(true);
  };

  const handleMessageItemClicked = (messageId: string) => () => {
    //find message
    let message = data.getMessagesByOrder.find((message: any) => message.id === messageId);
    if (!message) {
      return;
    }

    //redirect to message view /messages/:messageThreadId/:token
    window.location.href = `/messages/${message.threadId}/${token}`
  }

  const handleSentMessageOnClose = (threadId: string) => {
    setOpenMessageModal(false);
    if (threadId !== null && threadId !== '') {
      window.location.href = `/messages/${threadId}/${token}`
    }
  }

  const sentMessageOrderObject = {
    id: data.getOrder.id,
    customerId: data.getOrder.customer.id,
    orderId: data.getOrder.id,
    estateAddress: data.getOrder.estateAddress,
    estateZipCode: data.getOrder.estateZipCode,
    estateCity: data.getOrder.estateCity,
    caseNo: data.getOrder.caseNo,
    orderType: 'ORDER'
  };

  return (
    <>
      <Card style={{ height: '100%' }}>
        <CardHeader title={t('MessagesHeader')} action={<><Button variant="outlined" onClick={handleNewMessageClicked} >{t('MessageCreateNewMessage')}</Button></>} ></CardHeader>
        <CardContent style={{ padding: '0px' }}>
          <div className="messageItemsHeading" key={'messageHeading'}>
            <div className="messageSubject">{t('MessagesItemHeaderSubject')}</div>
            
            <div className="messageDate">{t('MessagesItemHeaderRecived')}</div>
            
          </div>
          <div style={{ maxHeight: '320px', overflowY: 'auto' }} className="scrollbar" >
            {data.getMessagesByOrder.map((message: any) => {
              if (message.dateRead === null) {
                return (
                  <div className="messageItemUnread" key={message.id} onClick={handleMessageItemClicked(message.id)}>
                    <div className="messageSubjectView">
                    {message.messageSubject}
                    {message.messageSender === 'CUSTOMER' && (<FaRegPaperPlane    />)}
                    </div>
                    <div className="messageDate">{moment(message.createdAt).format("DD-MM-YYYY HH:mm")}</div>
                    
                  </div>
                )
              } else {
                return (
                  <div className="messageItem" key={message.id} onClick={handleMessageItemClicked(message.id)}>
                    <div className="messageSubjectView">
                      {message.messageSubject}
                      {message.messageSender === 'CUSTOMER' && (<FaRegPaperPlane    />)}
                    </div>
                    <div className="messageDate">{moment(message.createdAt).format("DD-MM-YYYY HH:mm")}</div>
                    
                  </div>
                )
              }
            })}
          </div>
        </CardContent>
      </Card>

      <SentMessageDialog key={'OrderSentMessage'} order={sentMessageOrderObject} isVisible={openMessageModal} onClose={handleSentMessageOnClose} messageType={messageTypeEnum.NORMAL_MESSAGE} />
    </>
  );
}

export default CustomerMessages;