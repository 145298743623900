
import React, { useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import './messageList.css';
import moment from "moment";
import SentMessageDialog, { messageTypeEnum } from "./sentMessageDialog";
import { FaPaperPlane } from "react-icons/fa";
import { datastore } from "./../../../datastore";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";


const MARK_THREAD_AS_READ_GQL = gql`
  mutation markMessagesAsRead($threadId: ID!) {
    markMessagesAsRead(threadId: $threadId)
  }
`;

const GET_MESSAGES_GQL = gql`
query getMessagesByUser($input: getMessagesByUserInput!){
  getMessagesByUser(input: $input){
    id
    threadId
    messageSubject
    messageText
    dateRead
    createdAt
    messageSender
  }
}`;


export enum MessageFilter {
  ALL = 'ALL',  // eslint-disable-line
  UNREAD = 'UNREAD',  // eslint-disable-line
  READ = 'READ',  // eslint-disable-line
}

interface IProps {
  fotographerUserIds: [String] | null
  searchStr: String
  onMessageSelected?: any
  order?: any
}

const MessageList: React.FC<IProps> = ({ fotographerUserIds, searchStr, onMessageSelected, order }) => {
  const { token } = useParams();
  const { t } = useTranslation();
  const [showSentNewMessage, setShowSentNewMessage] = useState(false);
  const [messagesRead, setMessagesRead] = useState<any[]>([]);
  const [filter, setFilter] = useState(MessageFilter.ALL);


  datastore.data.token = token + '';

  const [markMessagesAsRead] = useMutation(MARK_THREAD_AS_READ_GQL, {
    context: {
      headers: {
        authorization: "Bearer " + datastore.data.token,
      },
    }
  });
  const { loading, error, data, refetch } = useQuery(GET_MESSAGES_GQL, {
    variables: { input: { userIds: fotographerUserIds, searchStr: searchStr, filter: filter, orderId: order?.id } },
    context: {
      headers: {
        authorization: "Bearer " + datastore.data.token,
      },
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return (<p>Error getting message from server (1)</p>); //TODO: make error component
  }

  if (!data) {
    return (<p>Error getting message from server (2)</p>);
  }

  const closeSentNewMessage = (threadId: string) => {
    setShowSentNewMessage(false)
    if (threadId !== null && threadId !== '') {
      onMessageSelected(threadId, true);
    }
  }

  const handleCreateNewMessageClicked = () => {
    setShowSentNewMessage(true)
  }

  const handleRowClick = (message: any) => {
    if (onMessageSelected) {
      markMessagesAsRead({ variables: { threadId: message.threadId } }).then(() => {
        setMessagesRead([...messagesRead, message.threadId]);
      })
      onMessageSelected(message.threadId, false);
    }
  }

  const handleFilterChange = (filter: any) => {
    if (filter.target.checked) {
      setFilter(MessageFilter.UNREAD);
    } else {
      setFilter(MessageFilter.ALL);
    }
  }


  return (
    <>
      <SentMessageDialog key={'OrderSentMessage'} order={order ? order : null} isVisible={showSentNewMessage} onClose={(threadId: string) => { closeSentNewMessage(threadId) }} messageType={messageTypeEnum.NORMAL_MESSAGE} />

      <div className="messageListContainer">
        <div className="messageListContainerTop">
          <div className="messageFilter">
            <FormGroup aria-label="position" row>
              <FormControlLabel control={<Switch size="small" onChange={handleFilterChange} defaultChecked={filter === MessageFilter.UNREAD} />} className="switch" label={t('onlyUnreadMessages')} />
            </FormGroup>
          </div>
          <div className="createButton" onClick={handleCreateNewMessageClicked}>
            <FaPaperPlane />&nbsp; {t('messageViewCreateNewMessage')}
          </div>
        </div>


        <table className="messageTable">
          <tr>
            <th className="messageHeadingSubject">{t('messageViewSubject')}</th>
            <th className="messageHeadingCreatedAt">{t('messageViewCreated')}</th>
          </tr>
        </table>
        <div className="messageTableContainer scrollbar">


          <table className="messageTable">
            <tbody>
              {data.getMessagesByUser.map((message: any) => (
                <>
                  {
                    (message.dateRead === null && ["PHOTOGRAPHY_COMPANY", "PHOTOGRAPHER"].indexOf(message.messageSender) > -1 && messagesRead.indexOf(message.threadId) === -1) ? (
                      <tr key={message.id} onClick={() => { handleRowClick(message) }}>
                        <td className="messageSubject"><b>{message.messageSubject}</b></td>
                        <td style={{ textAlign: 'end', paddingRight: '5px' }}><b>{moment(message.createdAt).format("DD-MM-YYYY HH:mm")}</b></td>
                      </tr>
                    ) : (
                      <tr key={message.id} onClick={() => { handleRowClick(message) }}>
                        <td className="messageSubject">{message.messageSubject}</td>
                        <td style={{ textAlign: 'end', paddingRight: '5px' }}>{moment(message.createdAt).format("DD-MM-YYYY HH:mm")}</td>
                      </tr>
                    )}
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default MessageList;
