import React, { FC, useState, useEffect } from "react";
import './AmountSelector.css'

export interface AmountSelectorProps {
  initialAmount: number;
  productId: string;
  onAmountChange?: (productId: string, amount: number) => void;
}

const AmountSelector: FC<AmountSelectorProps> = ({ initialAmount,productId, onAmountChange }) => {
  const [amount, setAmount] = useState(initialAmount);

  useEffect(() => {
    if (onAmountChange) {
      onAmountChange(productId,amount);
    }
  }, [amount,productId, onAmountChange]);

  const incrementAmount = () => setAmount(amount + 1);
  const decrementAmount = () => setAmount(amount > 0 ? amount - 1 : 0);

  return (
    <div className="subProductItemAmountContainer">
      <div className="subProductItemAmountMinus" onClick={decrementAmount}>-</div>
      <div className="subProductItemAmount">{amount}</div>
      <div className="subProductItemAmountPlus" onClick={incrementAmount}>+</div>
    </div>
  );
};

export default AmountSelector;