import React, { useState } from "react";

import Error from "../error/error";
//import state, { StateParameter } from "../../utils/state";
import Grid from "@mui/material/Unstable_Grid2";
import "./orderList.css";
import { datastore } from "../../datastore";
import { useQuery, gql } from "@apollo/client";
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { Card, CardContent, Select } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DatePicker, { registerLocale } from 'react-datepicker';
import { da as daLocale, id } from 'date-fns/locale';
import "react-datepicker/dist/react-datepicker.css";

//import FormControlLabel from "@mui/material/FormControlLabel";
import TopMenu, { TopMenuItem, viewType } from "../../components/TopMenu/TopMenu";
import Logo from "../../components/Logo/Logo";
import moment from "moment";

const GET_ORDERS = gql`
  query getOrdersByCustomer($customerId: ID!,$startDate: Date!, $endDate: Date!, $status: StatusType) {
    getOrdersByCustomer(customerId: $customerId, startDate: $startDate, endDate: $endDate, status: $status) {
      id
      caseNo
      estateAddress
      estateZipCode
      estateCity
      status
      createdAt
      photeshotEvent {
        id
        start
      }
    }
  }
`;

registerLocale('da', daLocale);


const OrderList: React.FC = () => {
  const navigate = useNavigate();
  //Set startDate to 1 month ago
  const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 3)));
  //Set endDate to today
  const [endDate, setEndDate] = useState(new Date());
  const [selectStatus, setSelectView] = useState('ALL');


  const { loading, error, data } = useQuery(GET_ORDERS, {
    variables: {
      customerId: datastore.data.orderData.customerId ? datastore.data.orderData.customerId : localStorage.getItem("customerId"),
      startDate: startDate,
      endDate: endDate,
      status: selectStatus
    },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <div></div>;
  }

  if (error) {
    //return <Error errorCode={500} />;
  }

  if (!data) {
    //return (<Error errorCode={500} />);
  }

  const dateComparator = (v1: string, v2: string) => {
    if (v1 === '') {
      return 1;
    }
    if (v2 === '') {
      return -1;
    }
    const format = 'DD-MM-YYYY';
    const dateA = moment(v1, format).toDate() as Date;
    const dateB = moment(v2, format).toDate() as Date;
    return (dateB as any) - (dateA as any);
  };

  const handleStartDateChange = (date: Date) => {
    setStartDate(date);
    setTimeout(() => {
      //   updateData();
    }, 1000);
  }

  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
    setTimeout(() => {
      //  updateData();
    }, 1000);
  }

  const columns: GridColDef[] = [
    { field: 'caseNo', headerName: 'Sags nr.', width: 140, filterable: false },
    { field: 'estateAddress', headerName: 'Adresse', minWidth: 360, flex: 1 },
    { field: 'status', headerName: 'Status', minWidth: 300, align: 'left' },
    { field: 'createdAt', headerName: 'Oprettet', width: 120, align: 'left', sortComparator: dateComparator },
  ];

  const translateStatus = (order: any) => {
    switch (order.status) {
      case 'RECEIVED':
        return 'Modtaget'
      case 'SELLER_NOT_RESPONDING':
        return 'Kontaktperson træffes ikke'
      case 'AWAITING_RESPONSE_FROM_CONTACT_PERSON':
        return 'Afventer svar fra kontaktperson'
      case 'PUT_ON_HOLD':
        return 'Ordren er sat i bero'
      case 'FOTOSHOT_PLANED':
        return 'Fotografering planlagt' + (order.photeshotEvent ? ' - ' + moment(order.photeshotEvent.start).format("DD-MM-YYYY") : '')
      case 'BEING_PROCESSED':
        return 'Ordren efterbehandles'
      case 'PROCESSING_COMPLETED':
        return 'Efterbehandling er afsluttet'
      case 'DELIVERED':
        return 'Ordren er leveret'
      case 'INVOICED':
        return 'Ordren er faktureret'
      case 'CANCELED':
        return 'Ordren er annulleret'
      default:
        break;
    }
  }

  const rows = data.getOrdersByCustomer.map((order: any) => {
    return {
      id: order.id,
      caseNo: order.caseNo,
      estateAddress: order.estateAddress + ', ' + order.estateZipCode + ' ' + order.estateCity,
      status: translateStatus(order),
      createdAt: moment(order.createdAt).format("DD-MM-YYYY")
    }
  });

  const handleRowClicked = (row: any) => {

    navigate('/order/' + row.id);
    //window.location.href = '/order/' + row.id;
  }

  return (
    <div className="orderList MainArea">
      <header className="TopAreaMain">
        <Logo />
        <TopMenu selectedItem={TopMenuItem.OrderList} view={viewType.EstateAgent} customerName={datastore.data.customer.name} />
      </header>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className="contentContainer">
            <div className="Heading">Bestillinger</div>
            <Grid container spacing={0}>
              <Grid xs={12} style={{ height: '100vh', width: '100%', borderWidth: '0px' }}>
                <div className="dateRangeContainer">
                  <p style={{width:'85px'}}>Dato interval:</p>
                  <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => handleStartDateChange(date as Date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      locale={"da"}
                      dateFormat="dd/MM/yyyy"
                    />
                    <p> - </p>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => handleEndDateChange(date as Date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      locale={"da"}
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>

                  <div style={{display: 'flex', gap: '10px', alignItems: 'center', maxWidth: '250px'}}>
                    <p style={{paddingLeft:'20px', width:'65px'}}>Status: </p>
                    <select value={selectStatus} onChange={(e) => { setSelectView(e.target.value) }} style={{width:'100%'}}>
                      <option value={'ALL'}>Alle</option>
                      <option value={'RECEIVED'}>Modtaget</option>
                      <option value={'SELLER_NOT_RESPONDING'}>Kontaktperson træffes ikke</option>
                      <option value={'AWAITING_RESPONSE_FROM_CONTACT_PERSON'}>Afventer svar fra kontaktperson</option>
                      <option value={'PUT_ON_HOLD'}>Ordren er sat i bero</option>
                      <option value={'FOTOSHOT_PLANED'}>Fotografering planlagt</option>
                      <option value={'BEING_PROCESSED'}>Ordren efterbehandles</option>
                      <option value={'PROCESSING_COMPLETED'}>Efterbehandling er afsluttet</option>
                      <option value={'DELIVERED'}>Ordren er leveret</option>
                      <option value={'INVOICED'}>Ordren er faktureret</option>
                      <option value={'CANCELED'}>Ordren er annulleret</option>
                    </select>
                  </div>

                </div>

                <Paper elevation={0} >
                  <Card elevation={3} >

                    <CardContent>
                      <DataGrid
                        style={{ borderWidth: '0px' }}
                        onRowClick={handleRowClicked}
                        rows={rows}
                        columns={columns}
                        initialState={{
                          filter: {
                            filterModel: {
                              items: [],
                              quickFilterValues: [''],
                            },
                          },
                        }}
                        autoHeight={true}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}
                        sx={{
                          // disable cell selection style
                          '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                          },
                          // pointer cursor on ALL rows
                          '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer'
                          }
                        }}
                      />
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderList;
